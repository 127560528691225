import React from "react";
import "../TeleHealth.css";

function ESignature({
  esignatureFile,
  handleFileChange,
  errors,
  esignaturePreview,
  removeUploadedFile,
}) {
  return (
    <div className="form-section">
      <h6>E-Signature</h6>
      <div className="form-group">
        <label htmlFor="esignature">E-Signature:</label>
        <input
          type="file"
          id="esignature"
          name="esignature"
          onChange={(e) => handleFileChange(e, "esignature")}
          accept=".jpg,.jpeg,.png"
        />
        {errors?.esignature && <div className="error">{errors.esignature}</div>}
      </div>

      {/* E-Signature Preview */}
      {esignaturePreview && (
        <div className="file-preview-section">
          <h6>Signature Preview:</h6>
          <div className="file-preview-container">
            <div className="file-preview-item">
              <div className="file-preview" style={{ position: "relative" }}>
                <img
                  src={esignaturePreview}
                  alt="E-signature"
                  style={{ maxWidth: "200px", maxHeight: "100px" }}
                />
                <button
                  type="button"
                  className="remove-file-btn"
                  onClick={() => removeUploadedFile("esignature")}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    background: "rgba(255,255,255,0.7)",
                    border: "none",
                    borderRadius: "50%",
                    padding: "2px",
                    cursor: "pointer",
                  }}
                >
                  <i className="pi pi-trash" style={{ color: "red" }}></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ESignature;
