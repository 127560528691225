import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PrescriptionOrders.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../AdminPanel/Navbar/Navbar.tsx";
import Sidebar from "../../AdminPanel/SideBar/Sidebar.tsx";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import "../TeleHealth.css";
import { API_URL } from "../../../config";

const PrescriptionOrdersList = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));
  const pageSize = 10;

  const apiEndPoint = API_URL;
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    fetchOrders();
    fetchDoctors();
  }, [searchTerm]);

  const fetchOrders = async () => {
    try {
      const response = await fetch(
        `${apiEndPoint}/orders/prescription-orders-list?search=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }

      const data = await response.json();
      setOrders(data.data || []);
      setTotalPages(Math.ceil(data.totalCount / pageSize));
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching prescription orders");
      setLoading(false);
    }
  };

  const fetchDoctors = async () => {
    if (userRole === "admin") {
      try {
        const response = await fetch(
          `${apiEndPoint}/teledoctors/get-telehealth-active-doctors-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch doctors");
        }

        const data = await response.json();
        setDoctors(data.data || []);
      } catch (error) {
        toast.error("Error fetching doctors list");
      }
    }
  };

  const handleAssignDoctor = async () => {
    console.log("selectedDoctor", selectedDoctor);
    console.log("selectedOrder", selectedOrder);
    let selecteddoctor = selectedDoctor.id;
    if (!selecteddoctor || !selectedOrder) {
      toast.error("Please select a doctor");
      return;
    }

    try {
      const response = await fetch(
        `${apiEndPoint}/orders/assign-doctor-to-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            doctorId: selecteddoctor,
            orderId: selectedOrder,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to assign doctor");
      }

      toast.success("Doctor assigned successfully");
      setShowAssignModal(false);
      setSelectedDoctor(null);
      setSelectedOrder(null);
      fetchOrders();
    } catch (error) {
      toast.error("Error assigning doctor");
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const actionBodyTemplate = (rowData) => {
    console.log("rowData", rowData);
    return (
      <React.Fragment>
        {userRole == "admin" && (
          <Button
            icon="pi pi-user"
            title="Assign Doctor"
            rounded
            outlined
            className="mr-2 editBtnFocus"
            onClick={() => {
              setSelectedOrder(rowData.orderId);
              setShowAssignModal(true);
            }}
          />
        )}
        {rowData.status == "Pending" && userRole == "ciai doctor" && (
          <Button
            icon="pi pi-plus"
            title="Create Prescription"
            rounded
            outlined
            className="editBtnFocus"
            onClick={() =>
              navigate(`/create-prescription?orderId=${rowData.orderId}`)
            }
          />
        )}
        {rowData.status == "Completed" && userRole == "ciai doctor" && (
          <Button>Generated</Button>
        )}
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    if (rowData.fileUrls && rowData.fileUrls.length > 0) {
      return (
        <Image
          src={rowData.fileUrls[0]}
          alt="Prescription"
          width="50"
          preview
        />
      );
    }
    return null;
  };

  return (
    <div className="dashboard">
      <Sidebar isActive={isActive} />
      <div className="dashboardContainer">
        <Navbar
          isActive={isActive}
          handleChange={() => setIsActive(!isActive)}
        />
        <div className="users-page">
          <div className="container">
            <div className="users-list">
              <div className="list-header">
                <div className="header-users">
                  <h6>Prescription Orders</h6>
                </div>
                <div className="header-actions">
                  <span className="p-input-icon-left search-input">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search orders"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </span>
                </div>
              </div>

              <DataTable
                value={orders}
                paginator
                rows={pageSize}
                totalRecords={totalPages * pageSize}
                loading={loading}
                emptyMessage="No orders found"
                className="p-datatable-sm"
              >
                <Column
                  field="patientName"
                  header="Patient Name"
                  sortable
                  filter
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="contactNumber"
                  header="Contact Number"
                  sortable
                  filter
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  field="createdAt"
                  header="Date"
                  body={(rowData) => formatDate(rowData.orderPlacedDate)}
                  sortable
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  sortable
                  filter
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  header="Prescription"
                  body={imageBodyTemplate}
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  header="Actions"
                  body={actionBodyTemplate}
                  style={{ width: "20%" }}
                ></Column>
              </DataTable>
            </div>
          </div>

          <Dialog
            className="assign-doctor-modal"
            header="Assign Doctor"
            visible={showAssignModal}
            style={{ width: "30vw" }}
            onHide={() => {
              setShowAssignModal(false);
              setSelectedDoctor(null);
            }}
          >
            <div className="form-group">
              <label htmlFor="doctor">Select Doctor</label>
              <Dropdown
                id="doctor"
                value={selectedDoctor}
                options={doctors}
                onChange={(e) => setSelectedDoctor(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Select a doctor"
                className="w-full"
              />
            </div>
            <div className="form-actions">
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => {
                  setShowAssignModal(false);
                  setSelectedDoctor(null);
                }}
                className="p-button-text"
              />
              <Button
                label="Assign"
                icon="pi pi-check"
                onClick={handleAssignDoctor}
                autoFocus
              />
            </div>
          </Dialog>

          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionOrdersList;
