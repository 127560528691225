import React, { useState, useEffect } from "react";
import "../../AdminPanel/style.css";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { dashboardUsers } from "../../AdminPanel/data/data.js";
import Sidebar from "../../AdminPanel/SideBar/Sidebar.tsx";
import Navbar from "../../AdminPanel/Navbar/Navbar.tsx";
import CreateDoctor from "./CreateDoctor.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "@mui/material";
import { Tag } from "primereact/tag";
import stringifyReactNode from "rsuite/esm/internals/utils/stringifyReactNode";
import { API_URL } from "../../../config";

export default function PendingDoctorsList() {
  const UAToken = localStorage.getItem("accessToken");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  const [doctorsList, setDoctorsList] = useState([]);
  const [showEditMode, setShowEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [showAddMode, setShowAddMode] = useState(false);
  const [getReloadData, setGetReloadData] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
  ];

  const getSeverity = (status) => {
    switch (status) {
      case "Active":
        return "active-status";
      case "In-Active":
        return "In-active-status";
    }
  };

  const apiEndPoint = API_URL;

  useEffect(() => {
    setDoctorsList(dashboardUsers);
  }, []);

  useEffect(() => {
    if (getReloadData) {
      getDoctorsList();
      setGetReloadData(false);
    }
  }, [getReloadData]);

  useEffect(() => {
    getDoctorsList();
  }, [selectedStatus]);

  useEffect(() => {
    if (UAToken && UAToken !== "" && UAToken !== null) {
      navigate("/pendingDoctorsList");
    } else {
      navigate("/");
    }
  }, [UAToken]);

  const getDoctorsList = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const endpoint =
        selectedStatus === "pending"
          ? "/doctors/get-pending-doctors-list"
          : "/doctors/get-rejected-doctors-list";

      const response = await fetch(
        `${apiEndPoint}${endpoint}?search=&page=${1}&pageSize=${10}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        //localStorage.setItem("accessToken","");
        //navigate('/login');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const actualData = await response.json();
      setData(actualData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteConfirm = (slotId) => {
    confirmDialog({
      message: "Are you sure you want to delete this Doctor?",
      header: "Confirmation",
      accept: () => deleteDoctor(slotId),
    });
  };

  const handleChange = () => {
    setIsActive(!isActive);
  };

  const reviewConfirm = (data) => {
    localStorage.setItem("doctorDetails", JSON.stringify(data));

    //setSelectedUserId(data)

    navigate(`/reviewDoctor/${data.id}`);
  };

  const slotsConfirm = (slotId) => {
    navigate(`/slotpage/${slotId}`);
  };

  const statusBodyTemplate = (data) => {
    return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
  };

  const deleteDoctor = async (Id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await fetch(`${apiEndPoint}/doctors/delete-doctor/${Id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((actualData) => {
          console.log("actualData ", actualData);
          toast.success("Doctor Record Deleted Successfully.");
          getDoctorsList();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/* <Link style={{ color: '#0b7c69' }} title='Slots' onClick={() => slotsConfirm(rowData.id)} >Slots</Link>&nbsp; */}

        {/* <Tag className="review-doctor" style={{ background: '#0b7c69', color: '#fff', marginBottom: '3px' }}><Link style={{ color: 'white' }} title='Review' onClick={() => reviewConfirm(rowData)} >Review</Link></Tag>&nbsp;	 */}

        <Button
          style={{ background: "#0b7c69", color: "#fff" }}
          icon="pi pi-eye"
          title="Review"
          rounded
          outlined
          className="mr-2"
          onClick={() => reviewConfirm(rowData)}
        />
        <Button
          style={{ background: "#0b7c69", color: "#fff" }}
          icon="pi pi-pencil"
          title="Edit"
          rounded
          outlined
          className="mr-2"
          onClick={() => {
            setSelectedUserId(rowData);
            setShowEditMode(true);
          }}
        />

        <Button
          icon="pi pi-trash"
          title="Delete"
          rounded
          outlined
          className="deleteBtnFocus"
          onClick={() => deleteConfirm(rowData.id)}
        />
        {/* <Link href={`/slotpage/${rowData.id}`} target="_blank" style={{ cursor: 'pointer' }} underline="always">
					<div>Slots</div>
				</Link> */}
      </React.Fragment>
    );
  };
  console.log("selectedUserId", selectedUserId);

  return (
    <div className="dashboard">
      <Sidebar isActive={isActive} />
      <div className="dashboardContainer">
        <Navbar isActive={isActive} handleChange={handleChange} />
        <div className="users-page">
          <div className="container">
            <div className="users-list">
              <div className="addNewUser">
                <div
                  className="header-users"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h6>
                    {selectedStatus === "pending" ? "Pending" : "Rejected"}{" "}
                    Doctors List
                  </h6>
                  <Dropdown
                    value={selectedStatus}
                    options={statusOptions}
                    onChange={(e) => setSelectedStatus(e.value)}
                    className="doctor-dropdown"
                  />
                </div>
                {userRole !== "admin" && (
                  <button
                    className="addUserCls"
                    title="Add User"
                    onClick={() => setShowAddMode(true)}
                  >
                    <i
                      className="pi pi-plus"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    Create
                  </button>
                )}

                {/* <Link href={`/slotpage/${data.name}`} target="_blank" style={{ cursor: 'pointer' }} underline="always">
									<div>slot Info</div>
								</Link> */}
              </div>
              <DataTable value={data} paginator rows={10}>
                <Column
                  field="name"
                  header="Name"
                  sortable
                  filter
                  style={{ width: "22%" }}
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  sortable
                  filter
                  style={{ width: "22%" }}
                ></Column>
                <Column
                  field="specialization"
                  header="Specialization"
                  sortable
                  filter
                  style={{ width: "22%" }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  sortable
                  filter
                  style={{ width: "12%" }}
                ></Column>
                <Column
                  header="Actions"
                  body={actionBodyTemplate}
                  style={{ width: "16%" }}
                ></Column>
              </DataTable>
            </div>
          </div>
          <Dialog
            header="Create New Doctor"
            visible={showAddMode}
            style={{ width: "40vw" }}
            onHide={() => showAddMode(false)}
          >
            <CreateDoctor
              visible={showAddMode}
              userId={selectedUserId}
              setGetReloadData={setGetReloadData}
              setUserAdded={() => {
                showAddMode(false);
              }}
              //getAllUsers();
            />
          </Dialog>
          <Dialog
            header="Update Doctor"
            visible={showEditMode}
            style={{ width: "39vw" }}
            onHide={() => setShowEditMode(false)}
          >
            <CreateDoctor
              visible={showEditMode}
              updateDoctordata={selectedUserId}
              setUserEdited={() => {
                setShowEditMode(false);
                //getAllUsers();
              }}
              setGetReloadData={setGetReloadData}
            />
          </Dialog>
          <ConfirmDialog />
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
