import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../AdminPanel/Navbar/Navbar.tsx";
import Sidebar from "../../AdminPanel/SideBar/Sidebar.tsx";
import "./CreatePrescription.css";
import { API_URL } from "../../../config";

const initialMedicineState = {
  medicationName: "",
  composition: "",
  frequency: {
    morning: false,
    afternoon: false,
    night: false,
  },
  instructions: "",
  tobeTaken: "",
  duration: "",
  medicineId: "",
  quantity: "",
};

const CreatePrescription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [medicines, setMedicines] = useState([{ ...initialMedicineState }]);
  const [loading, setLoading] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [prescriptionDetails, setPrescriptionDetails] = useState({
    medicalHistory: "",
    provisionalDiagnosis: "",
    prescriptionInstructions: "",
    chiefComplaints: "",
  });
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [filteredMedicines, setFilteredMedicines] = useState([]);

  const tobeTakenOptions = [
    { label: "Orally", value: "orally" },
    { label: "Injection", value: "injection" },
  ];

  // Get appointmentId from query params
  const queryParams = new URLSearchParams(location.search);
  const appointmentId = queryParams.get("orderId");

  useEffect(() => {
    fetchMedicines();
  }, []);

  const fetchMedicines = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `https://ciaitelehealth.com/inventory/medicine/get-active-medicine-list?search=`,
        {
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("MEdicnes", response);
      if (!response.ok) {
        throw new Error("Failed to fetch medicines");
      }

      const data = await response.json();
      const medicines = data.data || [];

      // Format medicines for dropdown
      const formattedMedicines = medicines.map((medicine) => ({
        label: medicine.name,
        value: medicine.id,
        composition: medicine.composition || "",
      }));

      setMedicineOptions(formattedMedicines);
    } catch (error) {
      console.error("Error fetching medicines:", error);
      toast.error("Failed to load medicines");
    }
  };

  const searchMedicine = (event) => {
    setTimeout(() => {
      let query = event.query.toLowerCase();
      let filtered = medicineOptions.filter((medicine) =>
        medicine.label.toLowerCase().includes(query)
      );
      setFilteredMedicines(filtered);
    }, 250);
  };

  const onMedicineSelect = (index, selectedMedicine) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index] = {
      ...updatedMedicines[index],
      medicationName: selectedMedicine.label,
      medicineId: selectedMedicine.value,
      composition: selectedMedicine.composition,
    };
    setMedicines(updatedMedicines);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    if (field === "frequency") {
      updatedMedicines[index].frequency = {
        ...updatedMedicines[index].frequency,
        ...value,
      };
    } else {
      updatedMedicines[index][field] = value;
    }
    setMedicines(updatedMedicines);
  };

  const handlePrescriptionDetailsChange = (field, value) => {
    setPrescriptionDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const addMedicine = () => {
    setMedicines([...medicines, { ...initialMedicineState }]);
  };

  const removeMedicine = (index) => {
    const updatedMedicines = medicines.filter((_, i) => i !== index);
    setMedicines(updatedMedicines);
  };

  const handleSubmit = async () => {
    console.log("Medicines 123", medicines, appointmentId);
    if (!appointmentId) {
      toast.error("Appointment ID is required");
      return;
    }
    console.log("appointmentId 123", appointmentId);
    if (medicines.length === 0) {
      toast.error("At least one medicine is required");
      return;
    }

    // {
    //     "medicationName": "",
    //     "medicineId": "1973",
    //     "dosage": "",
    //     "strength": "",
    //     "instructions": "",
    //     "tobeTaken": "",
    //     "frequency": "morning,afternoon,night",
    //     "duration": "20",
    //     "quantity": "60"
    // }

    const payload = {
      orderId: parseInt(appointmentId),
      medicalHistory: prescriptionDetails.medicalHistory,
      provisionalDiagnosis: prescriptionDetails.provisionalDiagnosis,
      prescriptionInstructions: prescriptionDetails.prescriptionInstructions,
      chiefComplaints: prescriptionDetails.chiefComplaints,
      prescription: medicines.map((medicine) => ({
        medicationName: medicine.medicationName,
        composition: medicine.composition,
        frequency: Object.entries(medicine.frequency)
          .filter(([_, value]) => value)
          .map(([key]) => key)
          .join(","),
        instructions: medicine.instructions,
        tobeTaken: medicine.tobeTaken,
        duration: medicine.duration,
        medicineId: medicine.medicineId,
        quantity: medicine.quantity,
      })),
    };
    console.log("Payload", payload);
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/orders/create-prescription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to create prescription");
      }

      toast.success("Prescription created successfully");
      navigate("/prescriptionOrdersList");
    } catch (error) {
      toast.error("Error creating prescription");
    } finally {
      setLoading(false);
    }
  };

  const PreviewContent = () => (
    <div className="prescription-preview">
      <div className="prescription-details-preview">
        <div className="medicine-preview-header">Prescription Details</div>
        <div className="preview-row">
          <span className="preview-label">Chief Complaints:</span>
          <span className="preview-value">
            {prescriptionDetails.chiefComplaints || "Not specified"}
          </span>
        </div>
        <div className="preview-row">
          <span className="preview-label">Medical History:</span>
          <span className="preview-value">
            {prescriptionDetails.medicalHistory || "Not specified"}
          </span>
        </div>
        <div className="preview-row">
          <span className="preview-label">Provisional Diagnosis:</span>
          <span className="preview-value">
            {prescriptionDetails.provisionalDiagnosis || "Not specified"}
          </span>
        </div>
        <div className="preview-row">
          <span className="preview-label">Prescription Instructions:</span>
          <span className="preview-value">
            {prescriptionDetails.prescriptionInstructions || "Not specified"}
          </span>
        </div>
      </div>

      {medicines.map((medicine, index) => (
        <div key={index} className="medicine-preview">
          <div className="medicine-preview-header">Medicine {index + 1}</div>
          <div className="preview-row">
            <span className="preview-label">Medicine Name:</span>
            <span className="preview-value">
              {medicine.medicationName || "Not specified"}
            </span>
          </div>
          <div className="preview-row">
            <span className="preview-label">Composition:</span>
            <span className="preview-value">
              {medicine.composition || "Not specified"}
            </span>
          </div>
          <div className="preview-row">
            <span className="preview-label">Frequency:</span>
            <div className="preview-frequency">
              <span
                className={`preview-frequency-item ${
                  medicine.frequency.morning ? "active" : ""
                }`}
              >
                Morning
              </span>
              <span
                className={`preview-frequency-item ${
                  medicine.frequency.afternoon ? "active" : ""
                }`}
              >
                Afternoon
              </span>
              <span
                className={`preview-frequency-item ${
                  medicine.frequency.night ? "active" : ""
                }`}
              >
                Night
              </span>
            </div>
          </div>
          {medicine.instructions && (
            <div className="preview-row">
              <span className="preview-label">Instructions:</span>
              <span className="preview-value">{medicine.instructions}</span>
            </div>
          )}
          <div className="preview-row">
            <span className="preview-label">To be Taken:</span>
            <span className="preview-value">
              {medicine.tobeTaken || "Not specified"}
            </span>
          </div>
          <div className="preview-row">
            <span className="preview-label">Duration:</span>
            <span className="preview-value">
              {medicine.duration || "Not specified"}
            </span>
          </div>
          <div className="preview-row">
            <span className="preview-label">Quantity:</span>
            <span className="preview-value">
              {medicine.quantity || "Not specified"}
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          className="p-button-primary preview-ok-button"
          onClick={() => setShowPreviewDialog(false)}
        />
      </div>
    );
  };

  return (
    <div className="dashboard">
      <Sidebar isActive={isActive} />
      <div className="dashboardContainer">
        <Navbar
          isActive={isActive}
          handleChange={() => setIsActive(!isActive)}
        />
        <div className="users-page">
          <div className="container create-prescription-container">
            {/* <div className="list-header">
              <div className="header-users">
                <h6>Create Prescription</h6>
              </div>
            </div> */}

            <div className="prescription-details-form">
              <div className="medicine-form-header">
                <h5 className="medicine-form-title">Prescription Details</h5>
              </div>

              <div className="form-group">
                <label htmlFor="chiefComplaints">Chief Complaints</label>
                <InputTextarea
                  id="chiefComplaints"
                  value={prescriptionDetails.chiefComplaints}
                  onChange={(e) =>
                    handlePrescriptionDetailsChange(
                      "chiefComplaints",
                      e.target.value
                    )
                  }
                  rows={3}
                  placeholder="Enter patient's chief complaints"
                />
              </div>

              <div className="form-group">
                <label htmlFor="medicalHistory">Medical History</label>
                <InputTextarea
                  id="medicalHistory"
                  value={prescriptionDetails.medicalHistory}
                  onChange={(e) =>
                    handlePrescriptionDetailsChange(
                      "medicalHistory",
                      e.target.value
                    )
                  }
                  rows={3}
                  placeholder="Enter patient's medical history"
                />
              </div>

              <div className="form-group">
                <label htmlFor="provisionalDiagnosis">
                  Provisional Diagnosis
                </label>
                <InputTextarea
                  id="provisionalDiagnosis"
                  value={prescriptionDetails.provisionalDiagnosis}
                  onChange={(e) =>
                    handlePrescriptionDetailsChange(
                      "provisionalDiagnosis",
                      e.target.value
                    )
                  }
                  rows={3}
                  placeholder="Enter provisional diagnosis"
                />
              </div>

              <div className="form-group">
                <label htmlFor="prescriptionInstructions">
                  Prescription Instructions
                </label>
                <InputTextarea
                  id="prescriptionInstructions"
                  value={prescriptionDetails.prescriptionInstructions}
                  onChange={(e) =>
                    handlePrescriptionDetailsChange(
                      "prescriptionInstructions",
                      e.target.value
                    )
                  }
                  rows={3}
                  placeholder="Enter general instructions for the prescription"
                />
              </div>
            </div>

            {medicines.map((medicine, index) => (
              <div key={index} className="medicine-form">
                <div className="medicine-form-header">
                  <span className="medicine-form-title-inner">
                    Medicine: {index + 1}
                  </span>
                  {index > 0 && (
                    <Button
                      icon="pi pi-trash"
                      className="p-button-text p-button-danger"
                      onClick={() => removeMedicine(index)}
                    />
                  )}
                </div>

                <div className="form-row add-medicine-form">
                  <div className="form-group medicationName">
                    <label htmlFor={`medicationName-${index}`}>
                      Medication Name
                    </label>
                    <AutoComplete
                      className="medicine-autocomplete"
                      id={`medicationName-${index}`}
                      value={medicine.medicationName}
                      suggestions={filteredMedicines}
                      completeMethod={searchMedicine}
                      field="label"
                      forceSelection
                      placeholder="Search for a medicine"
                      onChange={(e) =>
                        handleInputChange(index, "medicationName", e.value)
                      }
                      onSelect={(e) => onMedicineSelect(index, e.value)}
                      aria-label="Medicines"
                      dropdownAriaLabel="Select Medicine"
                      dropdown={false}
                      showClear={true}
                      autoHighlight={true}
                      selectOnFocus={true}
                    />
                  </div>
                  <div className="form-group composition">
                    <label htmlFor={`composition-${index}`}>Composition</label>
                    <InputText
                      id={`composition-${index}`}
                      value={medicine.composition}
                      readOnly
                    />
                  </div>
                  <div className="form-group tobeTaken">
                    <label htmlFor={`tobeTaken-${index}`}>To be Taken</label>
                    <Dropdown
                      id={`tobeTaken-${index}`}
                      value={medicine.tobeTaken}
                      options={tobeTakenOptions}
                      onChange={(e) =>
                        handleInputChange(index, "tobeTaken", e.value)
                      }
                      placeholder="Select method"
                    />
                  </div>

                  <div className="form-group duration">
                    <label htmlFor={`duration-${index}`}>Duration</label>
                    <InputText
                      id={`duration-${index}`}
                      value={medicine.duration}
                      onChange={(e) =>
                        handleInputChange(index, "duration", e.target.value)
                      }
                    />
                  </div>

                  <div className="form-group quantity">
                    <label htmlFor={`quantity-${index}`}>Quantity</label>
                    <InputText
                      id={`quantity-${index}`}
                      value={medicine.quantity}
                      onChange={(e) =>
                        handleInputChange(index, "quantity", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-row second">
                  <div className="form-group">
                    <label>Frequency</label>
                    <div className="frequency-checkboxes">
                      <div>
                        <Checkbox
                          inputId={`morning-${index}`}
                          checked={medicine.frequency.morning}
                          onChange={(e) =>
                            handleInputChange(index, "frequency", {
                              morning: e.checked,
                            })
                          }
                        />
                        <label htmlFor={`morning-${index}`}>Morning</label>
                      </div>
                      <div>
                        <Checkbox
                          inputId={`afternoon-${index}`}
                          checked={medicine.frequency.afternoon}
                          onChange={(e) =>
                            handleInputChange(index, "frequency", {
                              afternoon: e.checked,
                            })
                          }
                        />
                        <label htmlFor={`afternoon-${index}`}>Afternoon</label>
                      </div>
                      <div>
                        <Checkbox
                          inputId={`night-${index}`}
                          checked={medicine.frequency.night}
                          onChange={(e) =>
                            handleInputChange(index, "frequency", {
                              night: e.checked,
                            })
                          }
                        />
                        <label htmlFor={`night-${index}`}>Night</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor={`instructions-${index}`}>
                      Instructions
                    </label>
                    <InputTextarea
                      id={`instructions-${index}`}
                      value={medicine.instructions}
                      onChange={(e) =>
                        handleInputChange(index, "instructions", e.target.value)
                      }
                      rows={3}
                    />
                  </div>
                </div>
              </div>
            ))}

            <button className="add-medicine-button" onClick={addMedicine}>
              <i className="pi pi-plus"></i>
              Add More Medicine
            </button>

            <div className="form-actions create-prescription">
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-secondary padding-10"
                onClick={() => navigate("/prescriptionOrdersList")}
              />
              <Button
                label="Preview"
                icon="pi pi-eye"
                className="p-button-secondary padding-10"
                onClick={() => setShowPreviewDialog(true)}
              />
              <Button
                label="Submit"
                icon="pi pi-check"
                loading={loading}
                className="padding-10"
                onClick={handleSubmit}
              />
            </div>

            <Dialog
              header="Prescription Preview"
              visible={showPreviewDialog}
              style={{ width: "80vw", maxWidth: "900px" }}
              modal
              onHide={() => setShowPreviewDialog(false)}
              footer={renderFooter()}
            >
              <PreviewContent />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePrescription;
