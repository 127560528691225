import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../../config";

function CreateSpecialization(props) {
  const { setGetReloadData } = props;

  const [errorSpecializationNameMessage, setErrorSpecializationNameMessage] =
    useState("");

  const [initialValues, setInitialValues] = useState({
    specialization: "",
  });
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const apiEndPoint = API_URL;

  const CreateSpecializationInfo = async () => {
    if (initialValues.specialization === "") {
      validationForm("specialization", initialValues.specialization);
    } else {
      const url = `${apiEndPoint}/specializations/create-specialization`;
      const formData = {
        specialization: initialValues.specialization,
      };
      const token = localStorage.getItem("accessToken");

      // const options = {
      // 	method: 'POST',
      // 	headers: {
      // 		'Authorization': `Bearer ${token}`,
      // 		'Content-Type': 'application/json',
      // 	},
      // 	body: JSON.stringify(formData),
      // };

      var bodyFormData = new FormData();
      bodyFormData.append("specialization", initialValues.specialization);
      //bodyFormData.append('specializationId', initialValues.specializationId);
      bodyFormData.append("image", file, file.name);

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          //'Content-Type': 'application/json',
          //'Content-Type': 'multipart/form-data',
          //'Content-Type': 'undefined'
        },
        body: bodyFormData,
      };

      await fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.msg === "Specialization created successfully") {
            setInitialValues({
              ...initialValues,
              phoneNumber: "",
              password: "",
            });
            setTimeout(() => {
              navigate("/specializationsList");
            }, 1000);
            props.setUserAdded();
            setGetReloadData(true);
            toast.success("Specialization Saved Successfully.");
          } else {
            toast.error(data.msg);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  const validationForm = (type, value) => {
    let errorMessage = "";
    if (type === "specialization") {
      if (value === "") {
        errorMessage = "Enter Specialization Name ";
      } else if (value.length < 3) {
        errorMessage = "Please enter atleast 3 Characters";
      }
      setErrorSpecializationNameMessage(errorMessage);
      setAllErrors({ ...allErrors, ["specializationNameErr"]: errorMessage });
    }
  };

  const [allErrors, setAllErrors] = useState({
    specializationNameErr: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitialValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validationForm(name, value);
  };

  const handleUploadImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/png", "image/svg+xml"];
      const maxSize = 4 * 1024 * 1024; // 2MB
      if (!validTypes.includes(selectedFile.type)) {
        setErrors({
          ...errors,
          image: "Please upload an image in JPEG or PNG format.",
        });
        return;
      }
      if (selectedFile.size > maxSize) {
        setErrors({
          ...errors,
          image: "Please upload an image smaller than 2MB.",
        });
        return;
      }
      setErrors({ ...errors, image: "" });
      setFile(selectedFile);
    }
  };

  return (
    <div className="user-view _add-view">
      <div className="box">
        <div className="row">
          <div>
            <p>
              <span>Specialization Name</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Specialization Name"
                name="specialization"
                value={initialValues.specialization}
                onChange={handleChange}
                // onChange={e => setInitialValues({ ...initialValues, specialization: e.target.value })}
              />
              <div className="errorSpName">
                {errorSpecializationNameMessage}
              </div>
            </p>
          </div>
          <div>
            <p>
              <label>Image</label>
              <br />
              <input type="file" onChange={handleUploadImage} />
              <div className="error">{errors.image}</div>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="container">
				<input type="text" className="textbox" placeholder="Textbox 1" />
				<input type="text" className="textbox" placeholder="Textbox 2" />
				<input type="text" className="textbox" placeholder="Textbox 3" />
				<input type="text" className="textbox" placeholder="Textbox 4" />
			</div> */}
      <div className="specialBtnCls button-container">
        <button
          type="button"
          className="button-cancle-cls"
          style={{ marginRight: "15px" }}
          onClick={() => props.setUserAdded()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button-savecls"
          onClick={() => CreateSpecializationInfo()}
        >
          Save
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateSpecialization;
