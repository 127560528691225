import React from "react";
import "../TeleHealth.css";

function DoctorDocuments({
  registrationDocs,
  identityProofs,
  handleFileChange,
  errors,
  uploadedRegistrationFiles,
  uploadedIdentityFiles,
  removeUploadedFile,
}) {
  return (
    <div className="form-section">
      <h6>Document Uploads</h6>

      <div className="form-group">
        <label htmlFor="registrationDocs">Registration Documents:</label>
        <input
          type="file"
          id="registrationDocs"
          onChange={(e) => handleFileChange(e, "registration")}
          accept=".pdf,.jpg,.jpeg,.png"
          multiple
        />
        {errors?.registrationDocs && (
          <div className="error">{errors.registrationDocs}</div>
        )}
      </div>

      {/* File Preview for Registration Documents */}
      {uploadedRegistrationFiles && uploadedRegistrationFiles.length > 0 && (
        <div className="file-preview-section">
          <h6>Uploaded Registration Documents</h6>
          <div className="file-preview-container">
            {uploadedRegistrationFiles.map((file, index) => (
              <div key={index} className="file-preview-item">
                <div className="file-preview" style={{ position: "relative" }}>
                  <img
                    src={file.previewUrl || file.url || "/pdfImage.png"}
                    alt={
                      file.type && file.type.includes("image")
                        ? `Registration document ${index + 1}`
                        : "PDF document"
                    }
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <button
                    type="button"
                    className="remove-file-btn"
                    onClick={() => removeUploadedFile("registration", index)}
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      background: "rgba(255,255,255,0.7)",
                      border: "none",
                      borderRadius: "50%",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="pi pi-trash" style={{ color: "red" }}></i>
                  </button>
                </div>
                {/* <div className="file-name">{file.name}</div> */}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="identityProofs">Identity Proofs:</label>
        <input
          type="file"
          id="identityProofs"
          onChange={(e) => handleFileChange(e, "identity")}
          accept=".pdf,.jpg,.jpeg,.png"
          multiple
        />
        {errors?.identityProofs && (
          <div className="error">{errors.identityProofs}</div>
        )}
      </div>

      {/* File Preview for Identity Proofs */}
      {uploadedIdentityFiles && uploadedIdentityFiles.length > 0 && (
        <div className="file-preview-section">
          <h6>Uploaded Identity Proofs</h6>
          <div className="file-preview-container">
            {uploadedIdentityFiles.map((file, index) => (
              <div key={index} className="file-preview-item">
                <div className="file-preview" style={{ position: "relative" }}>
                  <img
                    src={file.previewUrl || file.url || "/pdfImage.png"}
                    alt={
                      file.type && file.type.includes("image")
                        ? `Identity proof ${index + 1}`
                        : "PDF document"
                    }
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <button
                    type="button"
                    className="remove-file-btn"
                    onClick={() => removeUploadedFile("identity", index)}
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      background: "rgba(255,255,255,0.7)",
                      border: "none",
                      borderRadius: "50%",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="pi pi-trash" style={{ color: "red" }}></i>
                  </button>
                </div>
                {/* <div className="file-name">{file.name}</div> */}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="form-group">
        <p className="document-note">
          Note: Please ensure all uploaded documents are clear and legible.
          Supported formats are PDF, JPG, JPEG, and PNG.
        </p>
      </div>
    </div>
  );
}

export default DoctorDocuments;
