import React, { useEffect } from "react";
import "../TeleHealth.css";

function DoctorSpecializations({
  specializations,
  specializationList,
  handleArrayInputChange,
  addSpecialization,
  removeSpecialization,
  specializationDocs,
  handleFileChange,
  errors,
  uploadedSpecializationFiles,
  removeUploadedFile,
}) {
  return (
    <div className="form-section doctro-specs">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ margin: 0 }}>Specializations</h6>
        <button
          type="button"
          onClick={addSpecialization}
          className="button-savecls add-spec"
          style={{ padding: "5px 10px" }}
        >
          Add Specialization
        </button>
      </div>

      {specializations.map((specialization, index) => (
        <div
          key={index}
          className="array-input"
          style={{ marginBottom: "10px" }}
        >
          <select
            className="doctorsListCls form-control"
            style={{ display: "flex" }}
            name={`specializations[${index}].id`}
            value={specialization.id}
            onChange={(e) =>
              handleArrayInputChange(e, "specializations", index)
            }
          >
            <option value="">Select</option>
            {Array.isArray(specializationList) &&
              specializationList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.specialization}
                </option>
              ))}
          </select>

          <input
            type="text"
            name={`specializations[${index}].experience`}
            value={specialization.experience}
            onChange={(e) =>
              handleArrayInputChange(e, "specializations", index)
            }
            placeholder="Experience"
            style={{ marginLeft: "10px", paddingRight: "10px" }}
          />

          {specializations.length > 1 && (
            <button
              type="button"
              onClick={() => removeSpecialization(index)}
              className="button-cancle-cls"
              style={{
                marginLeft: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i className="pi pi-trash" style={{ color: "white" }}></i>
            </button>
          )}
        </div>
      ))}

      <div className="form-group">
        <label htmlFor="specializationDocs">Specialization Documents:</label>
        <input
          type="file"
          id="specializationDocs"
          onChange={(e) => handleFileChange(e, "specialization")}
          accept=".pdf,.jpg,.jpeg,.png"
          multiple
        />
        {errors?.specializationDocs && (
          <div className="error">{errors.specializationDocs}</div>
        )}
      </div>

      {/* File Preview Section */}
      {uploadedSpecializationFiles &&
        uploadedSpecializationFiles.length > 0 && (
          <div className="file-preview-section">
            <h6>Uploaded Specialization Documents</h6>
            <div className="file-preview-container">
              {uploadedSpecializationFiles.map((file, index) => (
                <div key={index} className="file-preview-item">
                  <div
                    className="file-preview"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={file.previewUrl || file.url || "/pdfImage.png"}
                      alt={
                        file.type && file.type.includes("image")
                          ? `Specialization document ${index + 1}`
                          : "PDF document"
                      }
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                    <button
                      type="button"
                      className="remove-file-btn"
                      onClick={() =>
                        removeUploadedFile("specialization", index)
                      }
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        background: "rgba(255,255,255,0.7)",
                        border: "none",
                        borderRadius: "50%",
                        padding: "2px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="pi pi-trash" style={{ color: "red" }}></i>
                    </button>
                  </div>
                  {/* <div className="file-name">{file.name}</div> */}
                </div>
              ))}
            </div>
          </div>
        )}
    </div>
  );
}

export default DoctorSpecializations;
