import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../AdminPanel/style.css";
import "./TelehealthDoctors.css";
import Sidebar from "../../AdminPanel/SideBar/Sidebar.tsx";
import Navbar from "../../AdminPanel/Navbar/Navbar.tsx";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import CreateTelehealthDoctor from "./CreateTelehealthDoctor";
import UpdateTelehealthDoctor from "./UpdateTelehealthDoctor";
import ViewTelehealthDoctor from "./ViewTelehealthDoctor";
import { API_URL } from "../../../config";

const TelehealthDoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [showAddMode, setShowAddMode] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [showViewMode, setShowViewMode] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [getReloadData, setGetReloadData] = useState(false);
  const navigate = useNavigate();
  const apiEndPoint = API_URL;

  useEffect(() => {
    fetchDoctors();
  }, []);

  useEffect(() => {
    if (getReloadData) {
      fetchDoctors();
      setGetReloadData(false);
    }
  }, [getReloadData]);

  const handleChange = () => {
    setIsActive(!isActive);
  };

  const fetchDoctors = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        `${apiEndPoint}/teledoctors/get-telehealth-doctors-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setDoctors(response.data.data || []);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch doctors");
      setLoading(false);
      if (err.response?.status === 401) {
        navigate("/login");
      }
    }
  };

  const deleteConfirm = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this doctor?",
      header: "Confirmation",
      accept: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.delete(
        `${apiEndPoint}/teledoctors/delete-telehealth-doctor/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Doctor deleted successfully");
      fetchDoctors();
    } catch (err) {
      toast.error("Failed to delete doctor");
      if (err.response?.status === 401) {
        navigate("/login");
      }
    }
  };

  const getSeverity = (status) => {
    switch (status) {
      case "Active":
        return "active-status";
      case "Inactive":
        return "In-active-status";
      default:
        return "";
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          style={{ background: "#0b7c69", color: "#fff" }}
          icon="pi pi-eye"
          title="View"
          rounded
          outlined
          className="mr-2"
          onClick={() => {
            setSelectedDoctor(rowData);
            setShowViewMode(true);
          }}
        />
        <Button
          style={{ background: "#0b7c69", color: "#fff" }}
          icon="pi pi-pencil"
          title="Edit"
          rounded
          outlined
          className="mr-2"
          onClick={() => {
            setSelectedDoctor(rowData);
            setShowEditMode(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          title="Delete"
          rounded
          outlined
          className="deleteBtnFocus"
          onClick={() => deleteConfirm(rowData.id)}
        />
      </React.Fragment>
    );
  };

  if (loading) {
    return (
      <div className="dashboard">
        <Sidebar isActive={isActive} />
        <div className="dashboardContainer">
          <Navbar isActive={isActive} handleChange={handleChange} />
          <div>Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard">
        <Sidebar isActive={isActive} />
        <div className="dashboardContainer">
          <Navbar isActive={isActive} handleChange={handleChange} />
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Sidebar isActive={isActive} />
      <div className="dashboardContainer">
        <Navbar isActive={isActive} handleChange={handleChange} />
        <div className="users-page">
          <div className="container">
            <div className="users-list">
              <div className="addNewUser doctor-list">
                <div
                  className="header-users"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h6>Telehealth Doctors List</h6>
                </div>
                <button
                  className="addUserCls"
                  title="Add Doctor"
                  onClick={() => setShowAddMode(true)}
                >
                  <i className="pi pi-plus" style={{ paddingRight: "6px" }}></i>{" "}
                  Create
                </button>
              </div>
              <DataTable value={doctors} paginator rows={10}>
                <Column
                  field="name"
                  header="Name"
                  sortable
                  filter
                  style={{ width: "22%" }}
                ></Column>
                <Column
                  field="phoneNumber"
                  header="Phone Number"
                  sortable
                  filter
                  style={{ width: "22%" }}
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  sortable
                  filter
                  style={{ width: "22%" }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  sortable
                  filter
                  style={{ width: "12%" }}
                ></Column>
                <Column
                  header="Actions"
                  body={actionBodyTemplate}
                  style={{ width: "16%" }}
                ></Column>
              </DataTable>
            </div>
          </div>

          <Dialog
            header="Add Telehealth Doctor"
            visible={showAddMode}
            style={{ width: "40vw" }}
            onHide={() => setShowAddMode(false)}
          >
            <CreateTelehealthDoctor
              setGetReloadData={setGetReloadData}
              onClose={() => setShowAddMode(false)}
            />
          </Dialog>

          <Dialog
            header="Edit Telehealth Doctor"
            visible={showEditMode}
            style={{ width: "40vw" }}
            onHide={() => setShowEditMode(false)}
          >
            <UpdateTelehealthDoctor
              doctor={selectedDoctor}
              setGetReloadData={setGetReloadData}
              onClose={() => setShowEditMode(false)}
            />
          </Dialog>

          <Dialog
            header="View Telehealth Doctor"
            visible={showViewMode}
            style={{ width: "40vw" }}
            onHide={() => setShowViewMode(false)}
          >
            <ViewTelehealthDoctor
              doctor={selectedDoctor}
              onEdit={() => {
                setShowViewMode(false);
                setShowEditMode(true);
              }}
              onClose={() => setShowViewMode(false)}
            />
          </Dialog>

          <ConfirmDialog />
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default TelehealthDoctorsList;
