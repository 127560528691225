import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from 'primereact/inputswitch';
import { API_URL } from "../../../config";

function UpdateSachivalayam(props) {
	const { updateData, setGetReloadData } = props;
	const navigate = useNavigate();
	const apiEndPoint = API_URL;

	const updateStatus = updateData?.status === 'Active';

	const [questStatus, setquestStatus] = useState(updateStatus);
	const [errors, setErrors] = useState({
		name: "",
		code: "",
		mandalId: "",
		anmName: "",
		anmPhoneNumber: ""
	});

	const [initialValues, setInitialValues] = useState({
		name: updateData?.name || '',
		code: updateData?.code || '',
		mandalId: updateData?.mandalId || '',
		anmName: updateData?.anmName || '',
		anmPhoneNumber: updateData?.anmPhoneNumber || ''
	});

	const [data, setData] = useState([]);

	useEffect(() => {
		getMandalsList();
	}, []);

	const getMandalsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/mandals/get-mandals-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const validateField = (name, value) => {
		let error = "";
		const phonePattern = /^[0-9]{10}$/;

		switch(name) {
			case "name":
				if (!value) {
					error = "Enter Sachivalayam Name";
				} else if (value.length < 3) {
					error = "Please enter at least 3 characters";
				} else if (value.length > 20) {
					error = "Please enter less than 20 characters";
				}
				break;

			case "mandalId":
				if (!value) {
					error = "Select Mandal Name";
				}
				break;

			case "anmName":
				if (!value) {
					error = "Enter ANM Name";
				} else if (value.length < 3) {
					error = "Please enter at least 3 characters";
				} else if (value.length > 50) {
					error = "Please enter less than 50 characters";
				}
				break;

			case "anmPhoneNumber":
				if (!value) {
					error = "Enter ANM Phone Number";
				} else if (!phonePattern.test(value)) {
					error = "Enter valid 10-digit number";
				}
				break;

			default:
				break;
		}

		setErrors(prev => ({
			...prev,
			[name]: error
		}));

		return error;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues(prev => ({
			...prev,
			[name]: value
		}));
		validateField(name, value);
	};

	const validateForm = () => {
		let isValid = true;
		const fieldsToValidate = ['name', 'mandalId', 'anmName', 'anmPhoneNumber'];

		fieldsToValidate.forEach(field => {
			const error = validateField(field, initialValues[field]);
			if (error) {
				isValid = false;
			}
		});

		return isValid;
	};

	const UpdateSachivalayamData = async () => {
		if (!validateForm()) {
			return;
		}

		try {
			const token = localStorage.getItem('accessToken');
			const formData = {
				id: updateData?.id,
				...initialValues,
				status: questStatus ? 'Active' : 'In-Active'
			};

			const response = await fetch(`${apiEndPoint}/sachivalayams/update-sachivalayam`, {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});

			const data = await response.json();
			
			if (data.msg === "Sachivalayam information updated successfully") {
				toast.success("Sachivalayam information updated successfully");
				setGetReloadData(true);
				props.setUserEdited();
				setTimeout(() => navigate('/sachivalayamsList'), 2000);
			} else {
				toast.error(data.message || "Failed to update sachivalayam");
			}
		} catch (error) {
			console.error('Error:', error);
			toast.error("Failed to update sachivalayam");
		}
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<form>
						<div className="form-group">
							<label>Sachivalayam Name</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Sachivalayam Name'
								name='name'
								value={initialValues.name}
								onChange={handleChange}
							/>
							<div className="error">{errors.name}</div>
						</div>

						<div className="form-group">
							<label>Code</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Code'
								name='code'
								value={initialValues.code}
								onChange={handleChange}
							/>
							<div className="error">{errors.code}</div>
						</div>

						<div className="form-group">
							<label>Mandal Name</label>
							<select
								className="form-control"
								name='mandalId'
								value={initialValues.mandalId}
								onChange={handleChange}
							>
								<option value="">Select Mandal</option>
								{data.map((option) => (
									<option
										key={option.id}
										value={option.id}
									>
										{option.name}
									</option>
								))}
							</select>
							<div className="error">{errors.mandalId}</div>
						</div>

						<div className="form-group">
							<label>ANM Name</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter ANM Name'
								name='anmName'
								value={initialValues.anmName}
								onChange={handleChange}
							/>
							<div className="error">{errors.anmName}</div>
						</div>

						<div className="form-group">
							<label>ANM Phone Number</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter ANM Phone Number'
								name='anmPhoneNumber'
								value={initialValues.anmPhoneNumber}
								onChange={handleChange}
							/>
							<div className="error">{errors.anmPhoneNumber}</div>
						</div>

						<div className="checkbox-group">
							<label>Status</label>
							<InputSwitch 
								checked={questStatus}
								onChange={(e) => setquestStatus(e.value)}
								className="status-switch"
							/>
						</div>

						<div className="button-container">
							<button 
								type="button" 
								className="button-cancle-cls"
								onClick={() => props.setUserEdited()}
							>
								Cancel
							</button>
							<button 
								type="button" 
								className="button-savecls"
								onClick={UpdateSachivalayamData}
							>
								Update
							</button>
						</div>
					</form>
				</div>
			</div>
			<ToastContainer />
		</div>
	)
}

export default UpdateSachivalayam;
