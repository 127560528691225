import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { API_URL } from "../../../config";



function UpdateQuestion(props) {
    const { updateData,setUserEdited,setGetReloadData } = props;

	const [questStatus, setquestStatus] = useState(false);
    const [initialValues, setInitialValues] = useState({
        Question: updateData ? updateData?.question : '',
        option1: updateData ? updateData.questions[0]?.name : '',
        option2: updateData ? updateData.questions[1]?.name : '',
        option3: updateData ? updateData.questions[2]?.name : '',
        option4: updateData ? updateData.questions[3]?.name : ''
    });
	console.log('updateData',updateData)
    const navigate = useNavigate();
    const apiEndPoint = API_URL;

    const UpdateQuestionInfo = async () => {

        if (initialValues.Question === '') {
            toast.error("Please Enter Question.")
        }
        else if (updateData && updateData.questions.length === 0) {
            toast.error("Please Enter Question.")
        }
        else {
            const url = `${apiEndPoint}/symptoms/update-question`;
            const formData = {
                symptomId: updateData ?updateData?.symptomId :"",
				id: updateData ?updateData?.id : '',
                question: initialValues.Question,
                option1: initialValues.option1,
                option2: initialValues.option2,
                option3: initialValues.option3,
                option4: initialValues.option4,
                status: updateData ? updateData?.status : ''

            };
            const token = localStorage.getItem('accessToken');
            const options = {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            };

            await fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    setInitialValues({
                        ...initialValues,
                        Question: '',
                    });
                    setTimeout(() => {
                        navigate('/questionsList');
                    }, 2000)
					setUserEdited();
					setGetReloadData(true)
					toast.success("Question Updated Successfully.");

                })
                .catch(error => console.error('Error:', error));
        }
    }

    return (
        <div className='user-view _add-view'>
            <div className='box'>
                <div className='row'>
                    <div>
                        <p>
                            <span>Question</span>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Question Name'
                                value={initialValues.Question}
                                onChange={e => setInitialValues({ ...initialValues, Question: e.target.value })}
                            />
                        </p>

                        <p>
                            <span>Options </span><br />
                            <label>option1</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter option1'
                                value={initialValues.option1}
                                onChange={e => setInitialValues({ ...initialValues, option1: e.target.value })}
                            />

                            <label>option2</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter option2'
                                value={initialValues.option2}
                                onChange={e => setInitialValues({ ...initialValues, option2: e.target.value })}
                            />
                            <label>option3</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter option3'
                                value={initialValues.option3}
                                onChange={e => setInitialValues({ ...initialValues, option3: e.target.value })}
                            />
                            <label>option4</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter option4 '
                                value={initialValues.option4}
                                onChange={e => setInitialValues({ ...initialValues, option4: e.target.value })}
                            />
                        </p>
                        
                        <p>
                        <div style={{display:'flex'}}>
                            <div style={{paddingLeft: '2px'}}>Status</div>
                            <div style={{paddingLeft: '10px'}}><InputSwitch inputId="input-metakey" checked={questStatus} onChange={(e) => setquestStatus(e.value)} /></div>
                            </div>
                        </p>


                    </div>
                </div>
            </div>
            <div className='specialBtnCls button-container'>
                <button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => props.setUserEdited()}>Cancel</button>
                <button type="button" className="button-savecls" onClick={() => UpdateQuestionInfo()}>Update</button>
            </div>
            <br></br>
            <ToastContainer />
        </div>
    )
}

export default UpdateQuestion;