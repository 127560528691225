import React from 'react';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './TelehealthDoctors.css';

const ViewTelehealthDoctor = ({ doctor, onEdit, onClose }) => {
  if (!doctor) {
    return <div className="error-message">Doctor not found</div>;
  }

  return (
    <div className="user-view _add-view">
      <div className="box">
        <div className="row">
          <div>
            <p>
              <label>Name</label>
              <div className="details-value">{doctor.name}</div>
            </p>
          </div>

          <div>
            <p>
              <label>Phone Number</label>
              <div className="details-value">{doctor.phoneNumber}</div>
            </p>
          </div>

          <div>
            <p>
              <label>Email</label>
              <div className="details-value">{doctor.email}</div>
            </p>
          </div>

          <div>
            <p>
              <label>Status</label>
              <div className={`details-value status-${doctor.status?.toLowerCase()}`}>
                {doctor.status}
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="specialBtnCls">
        <button type="button" className="button-cancle-cls" onClick={onClose}>
          Close
        </button>
        <button type="button" className="button-savecls" onClick={onEdit}>
          Edit
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewTelehealthDoctor;
