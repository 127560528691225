import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear all localStorage items
        localStorage.clear();
        
        // Dispatch storage event to trigger App component update
        window.dispatchEvent(new Event('storage'));
        
        // Navigate to login page
        navigate('/');
    }, [navigate]);

    return null; // No need to render anything
}
