
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Login.css';
import { API_URL } from "../../../config";
const ForgetPassword = () => {
    const UAToken = localStorage.getItem("accessToken");

    const [initialValues, setInitialValues] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const navigate = useNavigate();
    const apiEndPoint = API_URL;

    useEffect(() => {
        if (UAToken && UAToken !== "" && UAToken !== null) {
            navigate('/');
        }
    }, [UAToken, navigate]);

    useEffect(() => {
        document.body.classList.add('bodyContainer_Cls');
        return () => {
            document.body.classList.remove('bodyContainer_Cls');
        };
    }, []);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInitialValues({ ...initialValues, [name]: value });
    };

    function handleSubmit(event) {
        event.preventDefault();

        if (initialValues.newPassword === '') {
            toast.error('Please Enter New Password');
        } else if (initialValues.newPassword.length !== 6) {
            toast.error('New Password Length Should be 6 Characters Only.');
        } else if (initialValues.confirmPassword === '') {
            toast.error('Please Enter Confirm Password');
        } else if (initialValues.confirmPassword.length !== 6) {
            toast.error('Confirm Password Length Should be 6 Characters Only.');
        } else if (initialValues.newPassword !== initialValues.confirmPassword) {
            toast.error('Passwords do not match.');
        } else {
            const formData = {
                newPassword: initialValues.newPassword,
                confirmPassword: initialValues.confirmPassword,
            };

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            };

            const url = `${apiEndPoint}/login/forgot-password`;
            fetch(url, options)
                .then(response => response.json())
                .then(data => {
                    if (data.msg === "success") {
                        toast.success("Password Reset Successfully.");
                        setInitialValues({ newPassword: '', confirmPassword: '' });
                        setTimeout(() => {
                            navigate('/');
                        }, 1000);
                    } else {
                        toast.error("Error resetting password. Please try again.");
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    toast.error("An error occurred. Please try again.");
                });
        }
    }

    return (
        <div>
            <div className='containeralignCls right-panel-active' id='container'>
                <div className='form-container sign-in-container'>
                    <form className='formAlignCls' onSubmit={handleSubmit}>
                        <h1 className='headerAlignCls'>FORGOT PASSWORD</h1>
                        <input
                            className='inputFieldAlignCls'
                            type='password'
                            placeholder='Enter New Password'
                            name='newPassword'
                            autoComplete='off'
                            maxLength={10}
                            value={initialValues.newPassword}
                            onChange={handleChange}
                        />
                        <input
                            className='inputFieldAlignCls'
                            type='password'
                            name='confirmPassword'
                            placeholder='Enter Confirm Password'
                            autoComplete='off'
                            maxLength={10}
                            value={initialValues.confirmPassword}
                            onChange={handleChange}
                        />
                        <div><button type='submit' className='buttonAlignCls'>Submit</button></div>
                    </form>
                </div>

                <div className='overlay-container'>
                    <div className='overlay'>
                        <div className='overlay-panel overlay-left'>
                            <div className='ml-4'>
                                <img width='210' height='90' src='./telehealth_Logo.png' alt='Logo' />
                            </div>&nbsp;&nbsp;
                            <h1 className='headerCls'>Welcome Back!</h1>
                            <p className='containAlignCls'>
                                To keep connected with us please login with your personal info
                            </p>
                        </div>
                        <div className='overlay-panel overlay-right'>
                            <h1>Hello, Friend!</h1>
                            <p>Enter your personal details and start journey with us</p>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ForgetPassword;



