import React, { useEffect } from "react";
import "../TeleHealth.css";

function DoctorQualifications({
  qualifications,
  qualificationsList,
  handleArrayInputChange,
  addQualification,
  removeQualification,
  qualificationDocs,
  handleFileChange,
  errors,
  uploadedQualificationFiles,
  removeUploadedFile,
}) {
  return (
    <div className="form-section doctro-specs">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <h6 style={{ margin: 0 }}>Qualifications</h6>
        <button
          type="button"
          onClick={addQualification}
          className="button-savecls add-spec"
          style={{ padding: "5px 10px" }}
        >
          Add Qualification
        </button>
      </div>

      {qualifications.map((qualification, index) => (
        <div
          key={index}
          className="array-input"
          style={{ marginBottom: "15px" }}
        >
          <select
            className="doctorsListCls form-control"
            style={{ display: "flex", marginRight: "10px" }}
            name={`qualifications[${index}].id`}
            value={qualification.id}
            onChange={(e) => handleArrayInputChange(e, "qualifications", index)}
          >
            <option value="">Select</option>
            {Array.isArray(qualificationsList) &&
              qualificationsList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.qualification}
                </option>
              ))}
          </select>

          <input
            type="text"
            name={`qualifications[${index}].college`}
            value={qualification.college}
            onChange={(e) => handleArrayInputChange(e, "qualifications", index)}
            placeholder="College"
            className="qual-college"
          />

          <input
            type="text"
            name={`qualifications[${index}].location`}
            value={qualification.location}
            onChange={(e) => handleArrayInputChange(e, "qualifications", index)}
            placeholder="Location"
            className="qual-loc"
          />

          <input
            type="text"
            name={`qualifications[${index}].fromYear`}
            value={qualification.fromYear}
            onChange={(e) => handleArrayInputChange(e, "qualifications", index)}
            placeholder="From Year"
            className="qual-from-year"
          />

          <input
            type="text"
            name={`qualifications[${index}].toYear`}
            value={qualification.toYear}
            onChange={(e) => handleArrayInputChange(e, "qualifications", index)}
            placeholder="To Year"
          />

          {qualifications.length > 1 && (
            <button
              type="button"
              onClick={() => removeQualification(index)}
              className="button-cancle-cls"
              style={{
                marginLeft: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i className="pi pi-trash" style={{ color: "white" }}></i>
            </button>
          )}
        </div>
      ))}

      <div className="form-group">
        <label htmlFor="qualificationDocs">Qualification Documents:</label>
        <input
          type="file"
          id="qualificationDocs"
          onChange={(e) => handleFileChange(e, "qualification")}
          accept=".pdf,.jpg,.jpeg,.png"
          multiple
        />
        {errors?.qualificationDocs && (
          <div className="error">{errors.qualificationDocs}</div>
        )}
      </div>

      {/* File Preview Section */}
      {uploadedQualificationFiles && uploadedQualificationFiles.length > 0 && (
        <div className="file-preview-section">
          <h6>Uploaded Qualification Documents</h6>
          <div className="file-preview-container">
            {uploadedQualificationFiles.map((file, index) => (
              <div key={index} className="file-preview-item">
                <div className="file-preview" style={{ position: "relative" }}>
                  <img
                    src={file.previewUrl || file.url || "/pdfImage.png"}
                    alt={
                      file.type && file.type.includes("image")
                        ? `Qualification document ${index + 1}`
                        : "PDF document"
                    }
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <button
                    type="button"
                    className="remove-file-btn"
                    onClick={() => removeUploadedFile("qualification", index)}
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      background: "rgba(255,255,255,0.7)",
                      border: "none",
                      borderRadius: "50%",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="pi pi-trash" style={{ color: "red" }}></i>
                  </button>
                </div>
                {/* <div className="file-name">{file.name}</div> */}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DoctorQualifications;
