import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../../config";

function CreateDistrict(props) {
  const { setGetReloadData } = props;

  const [errorDistrictNameMessage, setErrorDistrictNameMessage] = useState("");
  const [errorCodeMessage, setErrorCodeMessage] = useState("");
  const [errorStateIdMessage, setErrorStateIdMessage] = useState("");

  const [initialValues, setInitialValues] = useState({
    name: "",
    code: "",
    stateId: "",
  });
  const [districtsList, setDistrictsList] = useState([]);
  const statesData = {
    1: "Andaman and Nicobar Islands",
    2: "Andhra Pradesh",
    3: "Arunachal Pradesh",
    4: "Assam",
    5: "Bihar",
    6: "Chandigarh",
    7: "Chhattisgarh",
    8: "Dadra and Nagar Haveli",
    9: "Daman and Diu",
    10: "Delhi",
    11: "Goa",
    12: "Gujarat",
    13: "Haryana",
    14: "Himachal Pradesh",
    15: "Jammu and Kashmir",
    16: "Jharkhand",
    17: "Karnataka",
    18: "Kerala",
    19: "Ladakh",
    20: "Lakshadweep",
    21: "Madhya Pradesh",
    22: "Maharashtra",
    23: "Manipur",
    24: "Meghalaya",
    25: "Mizoram",
    26: "Nagaland",
    27: "Odisha",
    28: "Puducherry",
    29: "Punjab",
    30: "Rajasthan",
    31: "Sikkim",
    32: "Tamil Nadu",
    33: "Telangana",
    34: "Tripura",
    35: "Uttar Pradesh",
    36: "Uttarakhand",
    37: "West Bengal",
  };

  const statesList = Object.entries(statesData).map(([code, name]) => ({
    code,
    name,
  }));
  const navigate = useNavigate();
  const apiEndPoint = API_URL;

  useEffect(() => {
    getDistricts();
  }, []);

  const getDistricts = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${apiEndPoint}/districts/get-districts-list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const actualData = await response.json();
      setDistrictsList(actualData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validationForm = (type, value) => {
    let errorMessage = "";
    if (type === "name") {
      if (value === "") {
        errorMessage = "Enter District Name ";
      } else if (value.length < 3) {
        errorMessage = "Please enter atleast 3 Characters";
      }
      setErrorDistrictNameMessage(errorMessage);
      setAllErrors({ ...allErrors, ["nameErr"]: errorMessage });
    }

    if (type === "code") {
      if (value === "") {
        errorMessage = "Enter District Code";
      } else if (value.length < 2) {
        errorMessage = "Please enter at least 2 characters";
      } else if (value.length > 10) {
        errorMessage = "Please enter less than 10 characters";
      }
      setErrorCodeMessage(errorMessage);
      setAllErrors({ ...allErrors, ["codeErr"]: errorMessage });
    }
    if (type === "stateId") {
      if (value === "") {
        errorMessage = "Select State Name";
      }
      setErrorStateIdMessage(errorMessage);
      setAllErrors({ ...allErrors, ["stateIdErr"]: errorMessage });
    }
  };

  const [allErrors, setAllErrors] = useState({
    nameErr: "",
    codeErr: "",
    stateIdErr: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitialValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validationForm(name, value);
  };

  const CreateDistrictData = async () => {
    if (
      initialValues.name === "" ||
      initialValues.code === "" ||
      initialValues.stateId === ""
    ) {
      validationForm("name", initialValues.name);
      validationForm("code", initialValues.code);
      validationForm("stateId", initialValues.stateId);
    } else {
      const url = `${apiEndPoint}/districts/create-district`;

      const formData = {
        name: initialValues.name,
        code: initialValues.code,
        stateId: initialValues.stateId,
      };

      const token = localStorage.getItem("accessToken");
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };
      try {
        const response = await fetch(url, options);
        const data = await response.json();

        if (data.msg === "District created successfully") {
          toast.success("District Saved Successfully.");
          setInitialValues({
            ...initialValues,
            name: "",
            code: "",
            stateId: "",
          });
          props.setDistrictAdd();
          setGetReloadData(true);
          setTimeout(() => {
            navigate("/districtsList");
          }, 2000);
        } else {
          toast.error(data.message || "Failed to create district");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to create district");
      }
    }
  };

  return (
    <div className="user-view _add-view">
      <div className="box">
        <div className="row">
          <div>
            <p>
              <label>District Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter District Name"
                name="name"
                value={initialValues.name}
                onChange={handleChange}
              />
              <div className="errorSpName">{errorDistrictNameMessage}</div>
            </p>
          </div>

          <div>
            <p>
              <label>Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter District Code"
                name="code"
                value={initialValues.code}
                onChange={handleChange}
              />
              <div className="errorSpName">{errorCodeMessage}</div>
            </p>
          </div>
          <div>
            <p>
              <label>State Name</label>
              <select
                className="doctorsListCls form-control"
                name="stateId"
                value={initialValues.stateId}
                onChange={handleChange}
              >
                <option value="">Select</option>
                {statesList.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </select>
              <div className="errorSpName">{errorStateIdMessage}</div>
            </p>
          </div>
        </div>
      </div>
      <div className="specialBtnCls button-container">
        <button
          type="button"
          className="button-cancle-cls"
          style={{ marginRight: "15px" }}
          onClick={() => props.setDistrictAdd()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button-savecls"
          onClick={() => CreateDistrictData()}
        >
          Save
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateDistrict;
