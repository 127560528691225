import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from "primereact/inputswitch";
import { API_URL } from "../../../config";
function UpdateMandal(props) {
  const { updateData, setGetReloadData } = props;
  console.log("wwwwwwwww", updateData);
  const updateStatus =
    updateData && updateData?.status === "Active"
      ? true
      : updateData && updateData?.status === "In-Active"
      ? false
      : false;

  const [errorMandalNameMessage, setErrorMandalNameMessage] = useState("");
  const [errorDistrictIdMessage, setErrorDistrictIdMessage] = useState("");

  const [initialValues, setInitialValues] = useState({
    name: updateData ? updateData?.name : "",
    districtId: updateData ? updateData?.districtId : "",
  });
  const [data, setData] = useState([]);

  const [questStatus, setquestStatus] = useState(updateStatus);
  const navigate = useNavigate();
  const apiEndPoint = API_URL;

  useEffect(() => {
    getMandalsList();
  }, []);

  const getMandalsList = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const response = await fetch(`${apiEndPoint}/mandals/get-mandals-list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // localStorage.setItem("accessToken","");
        // navigate('/login');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const actualData = await response.json();
      setData(actualData.data);
      console.log("22222222222222222----->", actualData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validationForm = (type, value) => {
    console.log("name", type, value);
    let errorMessage = "";
    if (type === "name") {
      if (value === "") {
        errorMessage = "Enter Mandal Name ";
      } else if (value.length < 3) {
        errorMessage = "Please enter atleast 3 Characters";
      }

      setErrorMandalNameMessage(errorMessage);
      setAllErrors({ ...allErrors, ["nameErr"]: errorMessage });
    }

    if (type === "districtId") {
      if (value === "") {
        errorMessage = "Select District Id";
      }
      setErrorDistrictIdMessage(errorMessage);
      setAllErrors({ ...allErrors, ["districtIdErr"]: errorMessage });
    }
  };

  const [allErrors, setAllErrors] = useState({
    nameErr: "",
    districtIdErr: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("value123", name, value);
    setInitialValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validationForm(name, value);
  };

  const UpdateMandalData = async () => {
    if (initialValues.name === "" || initialValues.districtId === "") {
      validationForm("name", initialValues.name);
      validationForm("districtId", initialValues.districtId);
    } else {
      const url = `${apiEndPoint}/mandals/update-mandal`;
      const formData = {
        name: initialValues.name,
        id: updateData?.id,
        districtId: initialValues.districtId,
        status: questStatus === true ? "Active" : "In-Active",
      };
      const token = localStorage.getItem("accessToken");
      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };
      console.log("formData111111111111111", formData);
      await fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.msg === "Mandal information updated successfully") {
            setInitialValues({ ...initialValues, name: "", districtId: "" });
            setTimeout(() => {
              navigate("/mandalsList");
            }, 2000);
            toast.success("Mandal information updated successfully");
            props.setUserEdited();
            setGetReloadData(true);
          }
          // else {
          // 	toast.error("Internal server Error");
          // }
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  const handleChangeSwitch = (e) => {
    console.log(!questStatus);
    setquestStatus(!questStatus);
  };

  return (
    <div className="user-view _add-view">
      <div className="box">
        <div className="row">
          <div>
            <p>
              <span style={{ marginLeft: "12px" }}>Mandal Name</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Mandal Name"
                name="name"
                value={initialValues.name}
                onChange={handleChange}
              />
              <div className="errorSpName">{errorMandalNameMessage}</div>
            </p>
          </div>

          <div>
            <p>
              <label style={{ marginLeft: "12px" }}>District Name</label>
              <select
                className="doctorsListCls form-control"
                name="districtId"
                value={initialValues.districtId}
                onChange={handleChange}
              >
                <option value="">Select</option>
                {data.map((option, index) => (
                  <option key={index} value={option.districtId}>
                    {option.districtName}
                  </option>
                ))}
              </select>
              <div className="errorSpName">{errorDistrictIdMessage}</div>
            </p>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ paddingLeft: "2px" }}>Status</div>
            <div style={{ paddingLeft: "10px" }}>
              <InputSwitch
                inputId="input-metakey"
                checked={questStatus}
                onChange={handleChangeSwitch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="specialBtnCls button-container">
        <button
          type="button"
          className="button-cancle-cls"
          style={{ marginRight: "15px" }}
          onClick={() => props.setUserEdited()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button-savecls"
          onClick={() => UpdateMandalData()}
        >
          Update
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default UpdateMandal;
