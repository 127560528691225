import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from 'primereact/inputswitch';
import { API_URL } from "../../../config";

function UpdateDistrict(props) {
	const { updateData, setGetReloadData } = props;
	const navigate = useNavigate();
	const apiEndPoint = API_URL;

	const [errors, setErrors] = useState({
		name: "",
		code: ""
	});

	const [initialValues, setInitialValues] = useState({
		name: updateData?.name || '',
		code: updateData?.code || ''
	});

	const [questStatus, setQuestStatus] = useState(updateData?.status === 'Active');
	const [districtList, setDistrictList] = useState([]);

	useEffect(() => {
		getDistricts();
	}, []);

	const getDistricts = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/get-districts-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setDistrictList(actualData.data);
		} catch (error) {
			console.error('Error fetching districts:', error);
			toast.error("Failed to load districts");
		}
	};

	const validateField = (name, value) => {
		let error = "";
		switch (name) {
			case "name":
				if (!value) {
					error = "Enter District Name";
				} else if (value.length < 3) {
					error = "Please enter at least 3 characters";
				} else if (value.length > 50) {
					error = "Please enter less than 50 characters";
				}
				break;
			case "code":
				if (!value) {
					error = "Select Code";
				}
				break;
			default:
				break;
		}
		return error;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues(prev => ({
			...prev,
			[name]: value
		}));
		
		const error = validateField(name, value);
		setErrors(prev => ({
			...prev,
			[name]: error
		}));
	};

	const handleChangeSwitch = () => {
		setQuestStatus(prev => !prev);
	};

	const validateForm = () => {
		const newErrors = {
			name: validateField('name', initialValues.name),
			code: validateField('code', initialValues.code)
		};
		setErrors(newErrors);
		return !Object.values(newErrors).some(error => error !== "");
	};

	const UpdateDistrictData = async () => {
		if (!validateForm()) {
			return;
		}

		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/update-district`, {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					id: updateData?.id,
					name: initialValues.name,
					code: initialValues.code,
					stateId: updateData?.stateId,
					status: questStatus ? 'Active' : 'In-Active'
				}),
			});

			if (!response.ok) {
				throw new Error('Failed to update district');
			}

			const data = await response.json();
			if (response.status === 200) {
				toast.success("District updated successfully");
				setGetReloadData(true);
				props.setUserEdited();
				navigate('/districtsList');
			} else {
				toast.error(data.message || "Failed to update district");
			}
		} catch (error) {
			console.error('Error updating district:', error);
			toast.error("Failed to update district");
		}
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div className="form-group">
						<label>District Name</label>
						<input
							type='text'
							className='form-control'
							placeholder='Enter District Name'
							name='name'
							value={initialValues.name}
							onChange={handleChange}
						/>
						<div className="error">{errors.name}</div>
					</div>

					<div className="form-group">
						<label>Code</label>
						<select
							className="form-control"
							name='code'
							value={initialValues.code}
							onChange={handleChange}
						>
							<option value="">Select</option>
							{districtList.map((option) => (
								<option
									key={option.id}
									value={option.code}
								>
									{option.code}
								</option>
							))}
						</select>
						<div className="error">{errors.code}</div>
					</div>

					<div className="form-group">
						<div className="status-group">
							<label>Status</label>
							<InputSwitch 
								checked={questStatus} 
								onChange={handleChangeSwitch}
								className="status-switch"
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="button-container">
				<button 
					type="button" 
					className="button-cancle-cls" 
					onClick={props.setUserEdited}
				>
					Cancel
				</button>
				<button 
					type="button" 
					className="button-savecls" 
					onClick={UpdateDistrictData}
				>
					Update
				</button>
			</div>
			<ToastContainer />
		</div>
	);
}

export default UpdateDistrict;
