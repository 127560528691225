import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import '../TeleHealth.css';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CreateQuestion from './CreateQuestion.js';
import UpdateQuestion from './UpdateQuestion.js';
import { API_URL } from "../../../config";

function QuestionsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [data, setData] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [quesData, setQuesData] = useState([]);
	const [getReloadData, setGetReloadData] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const apiEndPoint = API_URL;

	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
			default:
				return '';
		}
	};

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/questionsList');
		} else {
			navigate('/')
		}
	}, [UAToken, navigate]);

	useEffect(() => {
		getQuestionsList();
	}, [searchTerm]);

	useEffect(() => {
		if (getReloadData) {
			getQuestionsList();
			setGetReloadData(false);
		}
	}, [getReloadData]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	const getQuestionsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/symptoms/get-questions-list?search=${searchTerm}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const actualData = await response.json();
			const formattedData = actualData.data.map((item, index) => {
				const options = [];
				if (item.option1) options.push({ id: index, name: item.option1 });
				if (item.option2) options.push({ id: index + 1, name: item.option2 });
				if (item.option3) options.push({ id: index + 2, name: item.option3 });
				if (item.option4) options.push({ id: index + 3, name: item.option4 });

				return {
					id: item.id,
					question: item.question,
					status: item.status,
					symptom: item.symptom,
					questions: options
				};
			});

			setData(formattedData);
		} catch (error) {
			console.error('Error fetching questions:', error);
			toast.error("Failed to load questions");
			if (error.message.includes('401')) {
				navigate('/login');
			}
		}
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button 
					icon="pi pi-pencil" 
					title='Edit' 
					rounded 
					outlined 
					className="mr-2 editBtnFocus" 
					onClick={() => {
						setSelectedRowItem(rowData)
						setShowEditMode(true)
					}}
				/>
				<Button 
					icon="pi pi-trash" 
					title='Delete' 
					rounded 
					outlined 
					className="deleteBtnFocus" 
					onClick={() => deleteQuestConfirm(rowData.id)}
				/>
			</React.Fragment>
		);
	};

	const optionBodyTemplate = (rowData) => {
		return (
			<Dropdown 
				value={rowData.questions[0]?.name || ""} 
				options={rowData.questions} 
				optionLabel="name"
				placeholder="View Options" 
				className="w-full"
				disabled
			/>
		);
	};

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	const deleteQuestConfirm = (questionId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Question?',
			header: 'Confirmation',
			accept: () => deleteQuestion(questionId),
		});
	}

	const deleteQuestion = async (id) => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/symptoms/delete-question/${id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error('Failed to delete question');
			}

			const data = await response.json();
			if (data.msg === "Question deleted successfully") {
				toast.success("Question deleted successfully");
				getQuestionsList();
			} else {
				toast.error(data.message || "Failed to delete question");
			}
		} catch (error) {
			console.error('Error deleting question:', error);
			toast.error("Failed to delete question");
		}
	}

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div>
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='list-header'>
									<div className='header-users'>
										<h6>Questions</h6>
									</div>
									<div className='header-actions'>
										<span className="p-input-icon-left search-input">
											<i className="pi pi-search" />
											<InputText 
												placeholder="Search questions" 
												value={searchTerm}
												onChange={handleSearch}
											/>
										</span>
										<button className='addUserCls' title='Add Question' onClick={() => setShowAddMode(true)}>
											<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
										</button>
									</div>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column 
										field="question" 
										header="Question" 
										sortable 
										filter
										style={{ width: '35%' }}
									/>
									<Column 
										field="symptom" 
										header="Symptom" 
										sortable 
										filter
										style={{ width: '15%' }}
									/>
									<Column 
										field="questions" 
										header="Options" 
										body={optionBodyTemplate}
										style={{ width: '25%' }}
									/>
									<Column 
										field="status" 
										header="Status" 
										body={statusBodyTemplate} 
										sortable 
										filter
										style={{ width: '10%' }}
									/>
									<Column 
										header="Actions" 
										body={actionBodyTemplate}
										style={{ width: '15%' }}
									/>
								</DataTable>
							</div>
						</div>
					</div>

					<Dialog 
						header="Add Question"
						visible={showAddMode}
						style={{ width: '40vw' }}
						onHide={() => setShowAddMode(false)}
					>
						<CreateQuestion 
							questInfo={quesData} 
							setGetReloadData={setGetReloadData} 
							handleClose={() => setShowAddMode(false)} 
						/>
					</Dialog>

					<Dialog 
						header="Edit Question"
						visible={showEditMode}
						style={{ width: '40vw' }}
						onHide={() => setShowEditMode(false)}
					>
						<UpdateQuestion 
							visible={showEditMode} 
							setGetReloadData={setGetReloadData} 
							updateData={selectedRowItem} 
							setUserEdited={() => setShowEditMode(false)} 
						/>
					</Dialog>

					<ConfirmDialog />
					<ToastContainer />
				</div>
			</div>
		</div>
	);
}

export default QuestionsList;
