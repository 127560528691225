import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../../config";

function CreateSymptom(props) {
  const { setGetReloadData } = props;

  const [errorSymptomNameMessage, setErrorSymptomNameMessage] = useState("");
  const [errorSpecializationIdMessage, setErrorSpecializationIdMessage] =
    useState("");

  const [initialValues, setInitialValues] = useState({
    symptom: "",
    image: "",
    specializationId: "",
  });

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [specializationList, setSpecializationList] = useState([]);
  const navigate = useNavigate();
  const apiEndPoint = API_URL;

  useEffect(() => {
    getSpecializations();
  }, []);

  const getSpecializations = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${apiEndPoint}/specializations/get-specializations-list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // localStorage.setItem("accessToken","");
        // navigate('/login');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const actualData = await response.json();
      setSpecializationList(actualData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CreateSymPtomData = async () => {
    if (initialValues.symptom === "" || initialValues.specializationId === "") {
      validationForm("symptom", initialValues.symptom);
      validationForm("specializationId", initialValues.specializationId);
    } else {
      const url = `${apiEndPoint}/symptoms/create-symptom`;

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onloadend = async () => {
        const arrayBuffer = reader.result;
        const binaryString = Array.from(new Uint8Array(arrayBuffer))
          .map((byte) => String.fromCharCode(byte))
          .join("");

        const formData = {
          symptom: initialValues.symptom,
          specializationId: initialValues.specializationId,
          image: binaryString,
        };

        const token = localStorage.getItem("accessToken");

        var bodyFormData = new FormData();
        bodyFormData.append("symptom", initialValues.symptom);
        bodyFormData.append("specializationId", initialValues.specializationId);

        bodyFormData.append("image", file, file.name);

        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            //'Content-Type': 'application/json',
            //'Content-Type': 'multipart/form-data',
            //'Content-Type': 'undefined'
          },
          body: bodyFormData,
        };

        await fetch(url, options)
          .then((response) => response.json())
          .then((data) => {
            if (data.msg === "Symptom created successfully") {
              setInitialValues({
                ...initialValues,
                symptom: "",
                specializationId: "",
                image: "",
              });
              setFile(null);
              setTimeout(() => {
                navigate("/symptomsList");
              }, 2000);
              toast.success("Symptom created successfully");
              props.setUserAdded();
              setGetReloadData(true);
            }
          })
          .catch((error) => console.error("Error:", error));
      };
    }
  };

  const validationForm = (type, value) => {
    let errorMessage = "";
    const newErrors = {};
    if (type === "symptom") {
      if (value === "") {
        errorMessage = "Enter Symptom Name ";
      } else if (value.length < 3) {
        errorMessage = "Please enter atleast 3 Characters";
      }
      setErrorSymptomNameMessage(errorMessage);
      setAllErrors({ ...allErrors, ["symptomNameErr"]: errorMessage });
    }
    if (type === "specializationId") {
      if (value === "") {
        errorMessage = "Select Specialization Id";
      }
      setErrorSpecializationIdMessage(errorMessage);
      setAllErrors({ ...allErrors, ["specializationIdErr"]: errorMessage });
    }
    if (!file) {
      newErrors.image = "Please upload an image.";
    }
    setErrors(newErrors);
    return errorMessage;
  };

  const [allErrors, setAllErrors] = useState({
    symptomNameErr: "",
    specializationIdErr: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validationForm(name, value);
  };

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/png", "image/svg+xml"];
      const maxSize = 4 * 1024 * 1024; // 2MB
      if (!validTypes.includes(selectedFile.type)) {
        setErrors({
          ...errors,
          image: "Please upload an image in JPEG or PNG format.",
        });
        return;
      }
      if (selectedFile.size > maxSize) {
        setErrors({
          ...errors,
          image: "Please upload an image smaller than 2MB.",
        });
        return;
      }
      setErrors({ ...errors, image: "" });
      setFile(selectedFile);
    }
  };

  return (
    <div className="user-view _add-view">
      <div className="box">
        <div className="row">
          <div>
            <p>
              <label>Symptom Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Symptom Name"
                name="symptom"
                value={initialValues.symptom}
                onChange={handleInputChange}
                // onChange={e => setInitialValues({ ...initialValues, symptom: e.target.value })}
              />
              <div className="error">{errorSymptomNameMessage}</div>
            </p>
          </div>
          <div>
            <p>
              <label>Specialization Id</label>
              <select
                className="doctorsListCls form-control"
                name="specializationId"
                value={initialValues.specializationId}
                onChange={handleInputChange}
                // onChange={e => setInitialValues({ ...initialValues, specializationId: e.target.value })}
              >
                <option value="">Select</option>
                {specializationList.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.specialization}
                  </option>
                ))}
              </select>
              <div className="error">{errorSpecializationIdMessage}</div>
            </p>
          </div>

          <div>
            <p>
              <label>Image</label>
              <br />
              <input type="file" onChange={handleChange} />
              <div className="error">{errors.image}</div>
            </p>
          </div>
        </div>
      </div>
      <div className="specialBtnCls button-container">
        <button
          type="button"
          className="button-cancle-cls"
          style={{ marginRight: "15px" }}
          onClick={() => props.setUserAdded()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button-savecls"
          onClick={() => CreateSymPtomData()}
        >
          Save
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateSymptom;
