import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DashBoard from "./components/AdminPanel/DashBoard/DashBoard.tsx";
import Users from "./components/AdminPanel/Users/Users.tsx";
import Login from "./components/TeleHealth/Login/Login.tsx";
import SlotPage from "./components/TeleHealth/Slots/SlotPage.js";
import SpecializationsList from "./components/TeleHealth/Specializations/SpecializationsList.js";
import SymptomsList from "./components/TeleHealth/Symptoms/SymptomsList.js";
import QualificationsList from "./components/TeleHealth/Qualifications/QualificationsList.js";
import DoctorsList from "./components/TeleHealth/Doctors/DoctorsList.js";
import PendingDoctorsList from "./components/TeleHealth/Doctors/PendingDoctorsList.js";
import CreateDoctor from "./components/TeleHealth/Doctors/CreateDoctor.js";
import EditDoctor from "./components/TeleHealth/Doctors/EditDoctor.js";
import Sample from "./components/AdminPanel/Sample/sample.js";
import Logout from "./components/TeleHealth/Logout/Logout.js";
import QuestionsList from "./components/TeleHealth/Questions/QuestionsList.js";
import ClinicsList from "./components/TeleHealth/Clinics/ClinicsList.js";
import CreateClinics from "./components/TeleHealth/Clinics/CreateClinics.js";
import UpdateClinics from "./components/TeleHealth/Clinics/UpdateClinics.js";
import MandalsList from "./components/TeleHealth/Mandals/MandalsList.js";
import CreateMandal from "./components/TeleHealth/Mandals/CreateMandal.js";
import UpdateMandal from "./components/TeleHealth/Mandals/UpdateMandal.js";
import DistrictsList from "./components/TeleHealth/Districts/DistrictsList.js";
import SachivalayamsList from "./components/TeleHealth/Sachivalayams/SachivalayamsList.js";
import CreateSlotPage from "./components/TeleHealth/Slots/CreateSlotPage.js";
import UpdateSlotPage from "./components/TeleHealth/Slots/UpdateSlotPage.js";
import ForgetPassword from "./components/TeleHealth/Login/ForgetPassword.js";
import ReviewDoctor from "./components/TeleHealth/Doctors/ReviewDoctor.js";
import CouponsList from "./components/TeleHealth/Coupons/CouponsList.js";
import TelehealthDoctorsList from "./components/TeleHealth/TelehealthDoctors/TelehealthDoctorsList.js";
import PrescriptionOrdersList from "./components/TeleHealth/PrescriptionOrders/PrescriptionOrdersList.js";
import CreatePrescription from "./components/TeleHealth/PrescriptionOrders/CreatePrescription.js";

function App() {
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("accessToken")
  );

  useEffect(() => {
    // Update state when localStorage changes
    const handleStorageChange = () => {
      setUserRole(localStorage.getItem("userRole"));
      setIsAuthenticated(localStorage.getItem("accessToken"));
    };

    window.addEventListener("storage", handleStorageChange);

    // Also check on mount
    handleStorageChange();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Redirect to dashboard if user is already authenticated
  const AuthRoute = ({ children }) => {
    if (isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    return children;
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        }
      />
      <Route
        path="/login"
        element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        }
      />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/dashboard" element={<DashBoard />} />
      <Route path="/users" element={<Users />} />

      <Route path="/doctorsList" element={<DoctorsList />} />
      <Route path="/createdoctor" element={<CreateDoctor />} />
      <Route path="/editDoctor/:doctorId" element={<EditDoctor />} />
      <Route path="/reviewDoctor/:id" element={<ReviewDoctor />} />
      <Route path="/pendingDoctorsList" element={<PendingDoctorsList />} />
      <Route path="/slotpage/:id/:name" element={<SlotPage />} />
      <Route path="/slotpage/:cid/:name/:docId" element={<SlotPage />} />
      <Route path="/createSlotpage/:Name" element={<CreateSlotPage />} />
      <Route path="/updateSlotpage/:Name" element={<UpdateSlotPage />} />
      <Route path="/logout" element={<Logout />} />

      {/* Admin-specific routes */}
      {userRole === "admin" && (
        <>
          <Route
            path="/specializationsList"
            element={<SpecializationsList />}
          />
          <Route path="/symptomsList" element={<SymptomsList />} />
          <Route path="/qualificationsList" element={<QualificationsList />} />
          <Route path="/sample" element={<Sample />} />
          <Route path="/questionsList" element={<QuestionsList />} />
          <Route path="/mandalsList" element={<MandalsList />} />
          <Route path="/districtsList" element={<DistrictsList />} />
          <Route path="/sachivalayamsList" element={<SachivalayamsList />} />
          <Route path="/clinicsList" element={<ClinicsList />} />
          <Route path="/createclinic" element={<CreateClinics />} />
          <Route path="/couponsList" element={<CouponsList />} />
          <Route
            path="/telehealth-doctors"
            element={<TelehealthDoctorsList />}
          />
          {/* <Route
            path="/prescriptionOrdersList"
            element={<PrescriptionOrdersList />}
          /> */}
          <Route path="/create-prescription" element={<CreatePrescription />} />
        </>
      )}
      {(userRole === "admin" || userRole === "ciai doctor") && (
        <>
          <Route
            path="/prescriptionOrdersList"
            element={<PrescriptionOrdersList />}
          />
          <Route path="/create-prescription" element={<CreatePrescription />} />
        </>
      )}

      {/* Clinic-specific routes */}
      {userRole === "clinic" && (
        <>
          <Route path="/sample" element={<Sample />} />
        </>
      )}
    </Routes>
  );
}

export default App;
