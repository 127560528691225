import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuProps, useStyles, options } from "./utils";
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns-tz';
import { InputSwitch } from 'primereact/inputswitch';
import '../TeleHealth.css';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import addDays from 'date-fns/addDays';
import { Stack } from 'rsuite';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { API_URL } from "../../../config";

export default function UpdateSlotPage(props) {

	const { setGetReloadData, updateDataInform, doctorId, doctorName, updateId } = props;
	console.log('updateDataInform----------------->', updateDataInform)

	let updatestatus = updateDataInform && updateDataInform?.status === 'Active' ? true :
		updateDataInform && updateDataInform?.status === 'In-Active' ? false : false;

	const [questStatus, setquestStatus] = useState(updatestatus);

	const classes = useStyles();
	const [selected, setSelected] = useState("");
	const [clinicsList, setClinicsList] = useState([]);
	const [currentTime, setCurrentTime] = useState(null);
	const [avalibleSlot, setAvalibleSlot] = useState([])
	const isAllSelected = avalibleSlot.length > 0 && selected.length === avalibleSlot.length;
	const [updateStatus, setUpdatetStatus] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const [errorClinicIdMessage, setErrorClinicIdMessage] = useState("");
	const [errorConsultationType, setErrorConsultationType] = useState("");
	const [errorDoctorTimezone, setErrorDoctorTimezone] = useState("");
	const [errorStartDate, setErrorStartDate] = useState("");
	const [errorEndDate, setErrorEndDate] = useState("");


	const navigate = useNavigate();
	const apiEndPoint = API_URL;

	const [initialValues, setInitialValues] = useState({
		clinicId: updateDataInform ? updateDataInform?.clinicId : '',
		consultationType: updateDataInform ? updateDataInform?.consultationType : '',
		startTime: updateDataInform ? updateDataInform?.formatStartDate : '',
		endTime: updateDataInform ? updateDataInform?.formatEndDate : '',
		doctorTimeZone: updateDataInform ? updateDataInform?.doctorTimeZone : '',
	});

	useEffect(() => {
		setAvalibleSlot(options.slots)
	}, [avalibleSlot])

	useEffect(() => {
		const fetchTime = () => {
			const timezone = 'Asia/Kolkata';
			const now = new Date();
			const formattedTime = format(now, 'MM/dd/yyyy HH:mm:ss', { timeZone: timezone });
			setCurrentTime(formattedTime);
		};

		fetchTime();

		const interval = setInterval(fetchTime, 1000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		getClinicsList();
	}, [])

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			// getDoctorsList();
		}
	}, []);

	const getClinicsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/clinics/get-clinics-list?search=&page=${1}&pageSize=${10}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setClinicsList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};


	const validationForm = (type, value) => {
		console.log('12345', type, value)
		let errorMessage = "";
		if (type === "clinicId") {
			if (value === '') {
				errorMessage = 'Select Clinic';
			}
			setErrorClinicIdMessage(errorMessage)
			setAllErrors({ ...allErrors, ['clinicIdErr']: errorMessage })
		}

		if (type === 'consultationType') {
			if (value === '') {
				errorMessage = 'Select Consultation Type';
			}
			setErrorConsultationType(errorMessage);
			setAllErrors({ ...allErrors, ['consultationTypeErr']: errorMessage })
		}
		if (type === 'doctorTimeZone') {
			if (value === '') {
				errorMessage = 'Select Doctor Timezone';
			}
			setErrorDoctorTimezone(errorMessage);
			setAllErrors({ ...allErrors, ['doctorTimezoneErr']: errorMessage })
		}

		if (type === 'startDate') {
			if (selected === '') {
				errorMessage = 'Select Start Time';
			}
			setErrorStartDate(errorMessage);
			setAllErrors({ ...allErrors, ['startDateErr']: errorMessage })
		}
		if (type === 'endDate') {
			if (selected === '') {
				errorMessage = 'Select End Time';
			}
			setErrorEndDate(errorMessage);
			setAllErrors({ ...allErrors, ['endDateErr']: errorMessage })
		}
	}

	const [allErrors, setAllErrors] = useState({
		clinicIdErr: "",
		consultationTypeErr: "",
		doctorTimezoneErr: "",
		startDateErr: "",
		endDateErr: ""
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};

	function formattedDate(date) {
		const hours = String(startDate.getHours()).padStart(2, '0');

		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		const ampm = hours >= 12 ? 'PM' : 'AM';

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;
		return [year, month, day].join('-') + ampm;
	}

	const UpdateSlotData = async () => {


		let formatStartDate = startDate !== null ? validateDateTime(startDate) : null;
		let formatEndDate = endDate !== null ? validateDateTime(endDate) : null;

		if (initialValues.clinicId === "" || formatStartDate === "" ||
			formatEndDate === "" || initialValues.consultationType === "" || initialValues.doctorTimeZone === ""
		) {
			validationForm('clinicId', initialValues.clinicId);
			validationForm('doctorId', doctorId);
			validationForm('consultationType', initialValues.consultationType);
			validationForm('doctorTimeZone', initialValues.doctorTimeZone);
			validationForm('startDate', formatStartDate)
			validationForm('endDate', formatEndDate)
		}
		else {
			const url = `${apiEndPoint}/slots/update-slot`;

			const formData = {
				id: updateId,
				clinicId: initialValues.clinicId,
				doctorId: doctorId,
				startTime: formatStartDate,
				endTime: formatEndDate,
				consultationType: initialValues.consultationType,
				doctorTimeZone: initialValues.doctorTimeZone,
				status: questStatus === true ? 'Active' : 'In-Active'

			};

			const token = localStorage.getItem('accessToken');

			const options = {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};
			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === "Slot information updated successfully") {
						setInitialValues({
							...initialValues,
							clinicId: '',
							startTime: "",
							endTime: "",
							consultationType: '',
							status: '',
							doctorTimeZone: ''
						});
						toast.success("Slot information updated successfully")
						setTimeout(() => {
							props.setUserEdited();
							setGetReloadData(true);
							navigate(`/slotpage/${doctorId}/${doctorName}/${doctorId}`);
						}, 2000)
					}
					// else if (data.msg === "slot already exists") { 
					// 	toast.success("Slot information updated successfully");				
					// }


				})

				.catch(error => console.error('Error:', error));
		}
	}

	const validateDateTime = (date) => {
		var d = new Date(date)
		let YYYY = d.getFullYear();
		let MM = '' + (d.getMonth() + 1);
		let DD = '' + d.getDate();
		let hh = d.getHours();
		let mm = d.getMinutes();
		const ampm = hh >= 12 ? 'PM' : 'AM';

		if (DD < 10) {
			DD = `0${DD}`;
		}
		if (MM < 10) {
			MM = `0${MM}`;
		}

		if (hh < 10) {
			hh = `0${hh}`;
		}
		if (mm < 10) {
			mm = `0${mm}`;
		}

		return [YYYY, MM, DD].join('-') + " " + [hh, mm].join(':') + " " + ampm;
	}


	const handleStartDate = (dateTime) => {
		setStartDate(dateTime);
		validationForm(selected)
		setErrorStartDate("");
	};

	const handleEndDate = (dateTime) => {
		setEndDate(dateTime);
		validationForm(selected)
		setErrorEndDate("");
	};

	const handleChangeSwitch = (e) => {
		setquestStatus(!questStatus);
	};

	return (
		<>
			<div className='create-slot-container'>
				<div className="form-group">
					<p>
						<label style={{ marginLeft: "20px" }}>Clinic</label>
						<select
							className="doctorsListCls form-control"
							style={{ display: 'flex', width: '97%' }}
							name='clinicId'
							value={initialValues.clinicId}
							onChange={handleChange}
						>
							<option value="">Select</option>
							{clinicsList.map((option, index) => (
								<option
									key={index}
									value={option.id}
								>
									{option.name}
								</option>
							))}
						</select>
						<div className='errorClinic'>{errorClinicIdMessage}</div>
					</p>
				</div>

				<div>
					<div className="flex-auto" style={{ paddingRight: '10px', marginLeft: '-6px', width: "429px" }}>
						<label htmlFor="calendar-24h" className="font-bold block mb-2" style={{ marginLeft: "25px" }}>
							Start Time:
						</label>
						<LocalizationProvider dateAdapter={AdapterDayjs} >
							<DemoContainer
								components={[
									'DateTimePicker',
								]}
							>
								<DemoItem >
									<DesktopDateTimePicker  value={startDate} onChange={(value) => handleStartDate(value)}
										slotProps={{
											textField: {
												InputProps: {
													placeholder: "Enter Start Time"
												},
											}
										}} />
								</DemoItem>
							</DemoContainer>
						</LocalizationProvider>
						<div className='errorStart'>{errorStartDate}</div>
					</div>
				</div>

				<div>
					<div className="flex-auto" style={{ paddingRight: '10px', marginLeft: '-6px', width: "429px" }}>
						<label htmlFor="calendar-12h" className="font-bold block mb-2" style={{ marginLeft: "25px" }}>
							End Time:
						</label>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer
								components={[
									'DateTimePicker',
								]}
							>
								<DemoItem >
									<DesktopDateTimePicker value={endDate} onChange={(value) => handleEndDate(value)} slotProps={{
										textField: {
											InputProps: {
												placeholder: "Enter End Time"
											},
										}
									}} />
								</DemoItem>
							</DemoContainer>
						</LocalizationProvider>
						<div className='errorEnd'>{errorEndDate} </div>
					</div>
				</div>

				<div className="form-group">
					<label htmlFor="consultationType" style={{ marginLeft: "18px" }}>Consultation Type:</label>
					<select name='consultationType' value={initialValues.consultationType} onChange={handleChange} className="doctorsListCls form-control"
						style={{ display: 'flex', width: '97%' }}>
						<option value="">Select</option>
						<option value="online">Online</option>
						<option value="offline">Offline</option>
					</select>
					<div className='errorType'>{errorConsultationType}</div>
				</div>

				<div className="form-group" style={{ width: '97%', marginInlineStart: '5px' }}>
					<label htmlFor="doctorTimeZone" style={{ marginLeft: "13px" }}>Doctor TimeZone:</label>
					<select name='doctorTimeZone' value={initialValues.doctorTimeZone} onChange={handleChange} className="doctorsListCls form-control"
						style={{ display: 'flex', width: '100%', marginLeft: '1px' }}>
						<option value="">Select</option>
						<option value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
						<option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
						<option value="Asia/Tehran">(GMT+03:30) Tehran</option>
						<option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
						<option value="Asia/Baku">(GMT+04:00) Baku</option>
						<option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
						<option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
						<option value="US/Alaska">(GMT-09:00) Alaska</option>
						<option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
						<option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
						<option value="US/Arizona">(GMT-07:00) Arizona</option>
						<option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
					</select>
					<div className='errorTimezone'>{errorDoctorTimezone}</div>
				</div>

				<div className="form-group">
					<div className="flex justify-content-center align-items-center mb-4 gap-2">
						<label htmlFor="input-metakey" style={{ marginLeft: '-266px' }}>Status:</label>
						<InputSwitch inputId="input-metakey" checked={questStatus} onChange={handleChangeSwitch} />
					</div>
				</div>

				<div style={{ textAlign: 'center', marginLeft: '110px' }}>
					<Stack direction="row" spacing={2}>
						<Button className='buttonSlotAlignCls' variant="contained" onClick={() => UpdateSlotData()}>Update</Button>
						<Button className='buttonSlotAlignCls1' variant="contained" style={{ marginTop: "-120px", marginLeft: "-50px" }} onClick={() => props.setUserEdited()}>Clear</Button>
					</Stack>
				</div>
				<ToastContainer />
			</div>
		</>
	);
}

