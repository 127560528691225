import React from "react";
import "../TeleHealth.css";

function RegistrationInformation({ initialValues, handleInputChange, errors }) {
  return (
    <div className="form-section doctor-reg-info">
      <h6>Registration Information</h6>

      <div className="form-group doctor-reg-info-mrn">
        <label htmlFor="medicalRegistrationNumber">
          Medical Registration Number:
        </label>
        <input
          type="text"
          id="medicalRegistrationNumber"
          name="medicalRegistrationNumber"
          value={initialValues.medicalRegistrationNumber}
          onChange={handleInputChange}
          placeholder="Enter Medical Registration Number"
        />
        {errors?.medicalRegistrationNumber && (
          <div className="error">{errors.medicalRegistrationNumber}</div>
        )}
      </div>

      <div className="form-group doctor-reg-id-proof">
        <label htmlFor="identityProofName">Identity Proof Name:</label>
        <input
          type="text"
          id="identityProofName"
          name="identityProofName"
          value={initialValues.identityProofName}
          onChange={handleInputChange}
          placeholder="Enter Identity Proof Name"
        />
        {errors?.identityProofName && (
          <div className="error">{errors.identityProofName}</div>
        )}
      </div>

      <div className="form-group doctor-reg-yearOfReg">
        <label htmlFor="yearOfRegistration">Year of Registration:</label>
        <input
          type="text"
          id="yearOfRegistration"
          name="yearOfRegistration"
          value={initialValues.yearOfRegistration}
          onChange={handleInputChange}
          placeholder="Enter Year of Registration"
        />
        {errors?.yearOfRegistration && (
          <div className="error">{errors.yearOfRegistration}</div>
        )}
      </div>

      <div className="form-group doctor-reg-medCouncil">
        <label htmlFor="medicalCouncilName">Medical Council Name:</label>
        <input
          type="text"
          id="medicalCouncilName"
          name="medicalCouncilName"
          value={initialValues.medicalCouncilName}
          onChange={handleInputChange}
          placeholder="Enter Medical Council Name"
        />
        {errors?.medicalCouncilName && (
          <div className="error">{errors.medicalCouncilName}</div>
        )}
      </div>
    </div>
  );
}

export default RegistrationInformation;
