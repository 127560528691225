import React, { useState, useEffect } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
import "./Coupons.css";
import { API_URL } from "../../../config";

const UpdateCoupon = ({ coupon, onClose, onSuccess }) => {
  const [couponData, setCouponData] = useState({
    code: "",
    description: "",
    discountType: "percentage",
    discountValue: "",
    maxDiscount: "",
    rule: "first-time",
    minPurchaseAmount: "",
    usageLimit: "",
    perUserLimit: "",
    usageType: "medicine",
  });

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  const discountTypes = [
    { label: "Percentage", value: "percentage" },
    { label: "Fixed Amount", value: "fixed" },
  ];

  const rules = [
    { label: "First Time", value: "first-time" },
    { label: "Everyone", value: "everyone" },
  ];

  const usageTypes = [
    { label: "Medicine", value: "medicine" },
    { label: "Appointment", value: "appointment" },
  ];

  useEffect(() => {
    if (coupon) {
      setCouponData({
        code: coupon.code || "",
        description: coupon.description || "",
        discountType: coupon.discountType || "percentage",
        discountValue: coupon.discountValue || "",
        maxDiscount: coupon.maxDiscount || "",
        rule: coupon.rule || "first-time",
        minPurchaseAmount: coupon.minPurchaseAmount || "",
        usageLimit: coupon.usageLimit || "",
        perUserLimit: coupon.perUserLimit || "",
        usageType: coupon.usageType || "medicine",
      });

      setStartDate(dayjs(coupon.startTime));
      setEndDate(dayjs(coupon.expiryTime));
    }
  }, [coupon]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        ...couponData,
        startTime: startDate.format("YYYY-MM-DD"),
        expiryTime: endDate.format("YYYY-MM-DD"),
      };

      const token = localStorage.getItem("accessToken");
      const response = await axios.put(
        `https://ciaitelehealth.com/api/coupons/update-coupon/${coupon.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Coupon updated successfully");
        onSuccess();
      }
    } catch (error) {
      console.error("Error updating coupon:", error);
      toast.error("Failed to update coupon");
    }
  };

  const handleChange = (name, value) => {
    setCouponData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="p-fluid create-slot-container">
      <div className="form-group">
        <label htmlFor="code">Coupon Code</label>
        <InputText
          id="code"
          value={couponData.code}
          onChange={(e) => handleChange("code", e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <InputTextarea
          id="description"
          value={couponData.description}
          onChange={(e) => handleChange("description", e.target.value)}
          rows={3}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="discountType">Discount Type</label>
        <Dropdown
          id="discountType"
          value={couponData.discountType}
          options={discountTypes}
          onChange={(e) => handleChange("discountType", e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="discountValue">Discount Value</label>
        <InputNumber
          id="discountValue"
          value={couponData.discountValue}
          onValueChange={(e) => handleChange("discountValue", e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="maxDiscount">Maximum Discount</label>
        <InputNumber
          id="maxDiscount"
          value={couponData.maxDiscount}
          onValueChange={(e) => handleChange("maxDiscount", e.value)}
          required
        />
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="form-group">
          <label>Start Date</label>
          <div>
            <DatePicker
              value={startDate}
              onChange={(newDate) => setStartDate(newDate)}
              sx={{ width: "100%" }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  fullWidth: true,
                },
              }}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Expiry Date</label>
          <div>
            <DatePicker
              value={endDate}
              onChange={(newDate) => setEndDate(newDate)}
              sx={{ width: "100%" }}
              minDate={startDate}
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  fullWidth: true,
                },
              }}
            />
          </div>
        </div>
      </LocalizationProvider>

      <div className="form-group">
        <label htmlFor="rule">Rule</label>
        <Dropdown
          id="rule"
          value={couponData.rule}
          options={rules}
          onChange={(e) => handleChange("rule", e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="usageType">Usage Type</label>
        <Dropdown
          id="usageType"
          value={couponData.usageType}
          options={usageTypes}
          onChange={(e) => handleChange("usageType", e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="minPurchaseAmount">Minimum Purchase Amount (₹)</label>
        <InputNumber
          id="minPurchaseAmount"
          value={couponData.minPurchaseAmount}
          onValueChange={(e) => handleChange("minPurchaseAmount", e.value)}
          mode="currency"
          currency="INR"
          locale="en-IN"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="usageLimit">Usage Limit</label>
        <InputNumber
          id="usageLimit"
          value={couponData.usageLimit}
          onValueChange={(e) => handleChange("usageLimit", e.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="perUserLimit">Per User Limit</label>
        <InputNumber
          id="perUserLimit"
          value={couponData.perUserLimit}
          onValueChange={(e) => handleChange("perUserLimit", e.value)}
          required
        />
      </div>

      <div className="dialog-footer">
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={onClose}
          className="p-button-text create-coupon-cancel"
        />
        <Button label="Update" icon="pi pi-check" type="submit" autoFocus />
      </div>
    </form>
  );
};

export default UpdateCoupon;
