import React, { useState, useEffect } from "react";
import "../../TeleHealth/TeleHealth.css";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../AdminPanel/Navbar/Navbar.tsx";
import Sidebar from "../../AdminPanel/SideBar/Sidebar.tsx";
import { toast } from "react-toastify";
import DoctorQualifications from "./DoctorQualifications";
import DoctorSpecializations from "./DoctorSpecializations";
import DoctorDocuments from "./DoctorDocuments";
import RegistrationInformation from "./RegistrationInformation";
import ESignature from "./ESignature";
import ConsultationInformation from "./ConsultationInformation";
import { API_URL } from "../../../config";

function EditDoctor() {
  const { doctorId } = useParams();
  const navigate = useNavigate();
  const apiEndPoint = API_URL;
  const UAToken = localStorage.getItem("accessToken");

  const [isActive, setIsActive] = useState(false);
  const [specializationList, setSpecializationList] = useState([]);
  const [QualificationsList, setQualificationList] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [isOfflineAvailable, setIsOfflineAvailable] = useState(false);
  const [isOnlineAvailable, setIsOnlineAvailable] = useState(false);
  const [doctorData, setDoctorData] = useState(null);
  const [loading, setLoading] = useState(true);

  // File upload states
  const [registrationDocs, setRegistrationDocs] = useState([]);
  const [identityProofs, setIdentityProofs] = useState([]);
  const [specializationDocs, setSpecializationDocs] = useState([]);
  const [qualificationDocs, setQualificationDocs] = useState([]);
  const [esignatureFile, setEsignatureFile] = useState(null);

  // File preview states
  const [uploadedRegistrationFiles, setUploadedRegistrationFiles] = useState(
    []
  );
  const [uploadedIdentityFiles, setUploadedIdentityFiles] = useState([]);
  const [uploadedSpecializationFiles, setUploadedSpecializationFiles] =
    useState([]);
  const [uploadedQualificationFiles, setUploadedQualificationFiles] = useState(
    []
  );
  const [esignaturePreview, setEsignaturePreview] = useState("");

  const [errors, setErrors] = useState({});

  const [initialValues, setInitialValues] = useState({
    name: "",
    bio: "",
    phoneNumber: "",
    email: "",
    isOfflineConsultationAvailable: false,
    isOnlineConsultationAvailable: false,
    consultationFee: "",
    gender: "",
    age: "",
    experience: "",
    medicalRegistrationNumber: "",
    identityProofName: "",
    yearOfRegistration: "",
    medicalCouncilName: "",
    specializations: [{ id: "", experience: "" }],
    qualifications: [
      { id: "", college: "", location: "", fromYear: "", toYear: "" },
    ],
    clinics: [],
  });

  useEffect(() => {
    if (UAToken && UAToken !== "" && UAToken !== null) {
      // Continue with the page
    } else {
      navigate("/");
    }
  }, [UAToken, navigate]);

  useEffect(() => {
    if (doctorId) {
      console.log("Fetching data for doctorId:", doctorId);
      fetchSpecializations();
      fetchQualifications();
      fetchDoctorData();
    }
  }, [doctorId]);

  const fetchDoctorData = async () => {
    if (!doctorId) {
      console.error("Doctor ID is undefined or null");
      toast.error("Invalid doctor ID");
      navigate("/doctorsList");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      const url = `${apiEndPoint}/doctors/get-doctor/${doctorId}`;
      console.log("Fetching doctor data from:", url);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("API Response:", result);
      if (result && result.data) {
        // Handle both array and object responses
        const doctorData = Array.isArray(result.data)
          ? result.data[0]
          : result.data;
        console.log("Doctor data:", doctorData);
        setDoctorData(doctorData);

        // Process specializations to use specializationId as id
        const processedSpecializations = doctorData.specializations?.length
          ? doctorData.specializations.map((spec) => ({
              ...spec,
              id: spec.specializationId || spec.id || "",
            }))
          : [{ id: "", experience: "" }];

        // Process qualifications to use qualificationId as id
        const processedQualifications = doctorData.qualifications?.length
          ? doctorData.qualifications.map((qual) => ({
              ...qual,
              id: qual.qualificationId || qual.id || "",
            }))
          : [{ id: "", college: "", location: "", fromYear: "", toYear: "" }];

        // Set form values
        setInitialValues({
          name: doctorData.name || "",
          bio: doctorData.bio || "",
          phoneNumber: doctorData.phoneNumber || "",
          email: doctorData.email || "",
          isOfflineConsultationAvailable:
            doctorData.isOfflineConsultationAvailable || false,
          isOnlineConsultationAvailable:
            doctorData.isOnlineConsultationAvailable || false,
          consultationFee: doctorData.consultationFee || "",
          gender: doctorData.gender || "",
          age: doctorData.age || "",
          experience: doctorData.experience || "",
          medicalRegistrationNumber: doctorData.medicalRegistrationNumber || "",
          identityProofName: doctorData.identityProofName || "",
          yearOfRegistration: doctorData.yearOfRegistration || "",
          medicalCouncilName: doctorData.medicalCouncilName || "",
          specializations: processedSpecializations,
          qualifications: processedQualifications,
          clinics: doctorData.clinics || [],
        });

        setSelectedGender(doctorData.gender || "");
        setIsOfflineAvailable(
          doctorData.isOfflineConsultationAvailable || false
        );
        setIsOnlineAvailable(doctorData.isOnlineConsultationAvailable || false);

        // If there are existing documents, set up the file previews
        if (doctorData.registrationDocs?.length) {
          setUploadedRegistrationFiles(
            doctorData.registrationDocs.map((url) => ({
              name: "Registration Document",
              type: url.toLowerCase().endsWith(".pdf")
                ? "application/pdf"
                : "image/jpeg",
              url: url,
            }))
          );
        }

        if (doctorData.identityProofDocs?.length) {
          setUploadedIdentityFiles(
            doctorData.identityProofDocs.map((url) => ({
              name: "Identity Document",
              type: url.toLowerCase().endsWith(".pdf")
                ? "application/pdf"
                : "image/jpeg",
              url: url,
            }))
          );
        }

        if (doctorData.specializationDocs?.length) {
          setUploadedSpecializationFiles(
            doctorData.specializationDocs.map((url) => ({
              name: "Specialization Document",
              type: url.toLowerCase().endsWith(".pdf")
                ? "application/pdf"
                : "image/jpeg",
              url: url,
            }))
          );
        }

        if (doctorData.qualificationDocs?.length) {
          setUploadedQualificationFiles(
            doctorData.qualificationDocs.map((url) => ({
              name: "Qualification Document",
              type: url.toLowerCase().endsWith(".pdf")
                ? "application/pdf"
                : "image/jpeg",
              url: url,
            }))
          );
        }

        // Set e-signature preview if available
        if (doctorData.eSignature) {
          setEsignaturePreview(doctorData.eSignature);
        }
      } else {
        toast.error("Invalid doctor data format");
        navigate("/doctorsList");
      }
    } catch (error) {
      console.error("Error fetching doctor data:", error);
      toast.error("Failed to fetch doctor data");
      navigate("/doctorsList");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      // Check file types
      const validTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const allValid = files.every((file) => validTypes.includes(file.type));

      if (!allValid) {
        toast.error("Only images and PDF files are allowed");
        return;
      }

      switch (type) {
        case "registration":
          setRegistrationDocs(files);
          setErrors((prev) => ({ ...prev, registrationDocs: "" }));
          // Add files to preview - using the same approach as e-signature
          setUploadedRegistrationFiles((prevFiles) => [
            ...prevFiles,
            ...files.map((file) => {
              const previewUrl = file.type.includes("image")
                ? URL.createObjectURL(file)
                : "/pdfImage.png";
              return {
                name: file.name,
                type: file.type,
                size: file.size,
                file: file, // Store the actual file object
                previewUrl: previewUrl, // Store the preview URL
              };
            }),
          ]);
          break;
        case "identity":
          setIdentityProofs(files);
          setErrors((prev) => ({ ...prev, identityProofs: "" }));
          // Add files to preview - using the same approach as e-signature
          setUploadedIdentityFiles((prevFiles) => [
            ...prevFiles,
            ...files.map((file) => {
              const previewUrl = file.type.includes("image")
                ? URL.createObjectURL(file)
                : "/pdfImage.png";
              return {
                name: file.name,
                type: file.type,
                size: file.size,
                file: file, // Store the actual file object
                previewUrl: previewUrl, // Store the preview URL
              };
            }),
          ]);
          break;
        case "specialization":
          setSpecializationDocs(files);
          setErrors((prev) => ({ ...prev, specializationDocs: "" }));
          // Add files to preview - using the same approach as e-signature
          setUploadedSpecializationFiles((prevFiles) => [
            ...prevFiles,
            ...files.map((file) => {
              const previewUrl = file.type.includes("image")
                ? URL.createObjectURL(file)
                : "/pdfImage.png";
              return {
                name: file.name,
                type: file.type,
                size: file.size,
                file: file, // Store the actual file object
                previewUrl: previewUrl, // Store the preview URL
              };
            }),
          ]);
          break;
        case "qualification":
          setQualificationDocs(files);
          setErrors((prev) => ({ ...prev, qualificationDocs: "" }));
          // Add files to preview - using the same approach as e-signature
          setUploadedQualificationFiles((prevFiles) => [
            ...prevFiles,
            ...files.map((file) => {
              const previewUrl = file.type.includes("image")
                ? URL.createObjectURL(file)
                : "/pdfImage.png";
              return {
                name: file.name,
                type: file.type,
                size: file.size,
                file: file, // Store the actual file object
                previewUrl: previewUrl, // Store the preview URL
              };
            }),
          ]);
          break;
        case "esignature":
          // Only accept image files for e-signature
          if (!files[0].type.includes("image")) {
            toast.error("E-signature must be an image file");
            return;
          }
          setEsignatureFile(files[0]);
          setErrors((prev) => ({ ...prev, esignature: "" }));
          // Create preview - this approach works well and should be used for all file types
          const previewUrl = URL.createObjectURL(files[0]);
          console.log("E-signature preview URL:", previewUrl);
          setEsignaturePreview(previewUrl);
          break;
        default:
          break;
      }
    }
  };

  // Function to remove uploaded files
  const removeUploadedFile = (type, index) => {
    switch (type) {
      case "registration":
        setUploadedRegistrationFiles((prev) =>
          prev.filter((_, i) => i !== index)
        );
        setRegistrationDocs((prev) => prev.filter((_, i) => i !== index));
        break;
      case "identity":
        setUploadedIdentityFiles((prev) => prev.filter((_, i) => i !== index));
        setIdentityProofs((prev) => prev.filter((_, i) => i !== index));
        break;
      case "specialization":
        setUploadedSpecializationFiles((prev) =>
          prev.filter((_, i) => i !== index)
        );
        setSpecializationDocs((prev) => prev.filter((_, i) => i !== index));
        break;
      case "qualification":
        setUploadedQualificationFiles((prev) =>
          prev.filter((_, i) => i !== index)
        );
        setQualificationDocs((prev) => prev.filter((_, i) => i !== index));
        break;
      case "esignature":
        setEsignatureFile(null);
        setEsignaturePreview("");
        break;
      default:
        break;
    }
  };

  const handleCheckbox = (type) => (event) => {
    const { checked } = event.target;
    if (type === "online") {
      setIsOnlineAvailable(checked);
      setInitialValues((prev) => ({
        ...prev,
        isOnlineConsultationAvailable: checked,
      }));
    } else {
      setIsOfflineAvailable(checked);
      setInitialValues((prev) => ({
        ...prev,
        isOfflineConsultationAvailable: checked,
      }));
    }
  };

  const handleGenderChange = (event) => {
    const value = event.target.value;
    setSelectedGender(value);
    setInitialValues((prev) => ({
      ...prev,
      gender: value,
    }));
  };

  const handleArrayInputChange = (e, arrayName, index) => {
    const { name, value } = e.target;
    setInitialValues((prev) => ({
      ...prev,
      [arrayName]: [
        ...prev[arrayName].slice(0, index),
        {
          ...prev[arrayName][index],
          [name.split(".")[1]]: value,
        },
        ...prev[arrayName].slice(index + 1),
      ],
    }));
  };

  const fetchSpecializations = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${apiEndPoint}/specializations/get-active-specializations-list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        setSpecializationList(data.data);
      } else {
        console.error("Invalid specializations data format:", data);
        toast.error("Invalid specializations data format");
      }
    } catch (error) {
      console.error("Error fetching specializations:", error);
      toast.error("Failed to load specializations");
    }
  };

  const fetchQualifications = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${apiEndPoint}/qualifications/get-active-qualifications-list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        setQualificationList(data.data);
      } else {
        console.error("Invalid qualifications data format:", data);
        toast.error("Invalid qualifications data format");
      }
    } catch (error) {
      console.error("Error fetching qualifications:", error);
      toast.error("Failed to load qualifications");
    }
  };

  const addSpecialization = () => {
    setInitialValues((prev) => ({
      ...prev,
      specializations: [...prev.specializations, { id: "", experience: "" }],
    }));
  };

  const removeSpecialization = (index) => {
    if (initialValues.specializations.length > 1) {
      setInitialValues((prev) => ({
        ...prev,
        specializations: prev.specializations.filter((_, i) => i !== index),
      }));
    }
  };

  const addQualification = () => {
    setInitialValues((prev) => ({
      ...prev,
      qualifications: [
        ...prev.qualifications,
        { id: "", college: "", location: "", fromYear: "", toYear: "" },
      ],
    }));
  };

  const removeQualification = (index) => {
    if (initialValues.qualifications.length > 1) {
      setInitialValues((prev) => ({
        ...prev,
        qualifications: prev.qualifications.filter((_, i) => i !== index),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("accessToken");
      const formData = new FormData();

      // Add basic fields
      formData.append("name", initialValues.name);
      formData.append("bio", initialValues.bio);
      formData.append("phoneNumber", initialValues.phoneNumber);
      formData.append("email", initialValues.email);
      formData.append("isOfflineConsultationAvailable", isOfflineAvailable);
      formData.append("isOnlineConsultationAvailable", isOnlineAvailable);
      formData.append("consultationFee", initialValues.consultationFee);
      formData.append("gender", selectedGender);
      formData.append("age", initialValues.age);
      formData.append("experience", initialValues.experience);
      formData.append(
        "medicalRegistrationNumber",
        initialValues.medicalRegistrationNumber
      );
      formData.append("identityProofName", initialValues.identityProofName);
      formData.append("yearOfRegistration", initialValues.yearOfRegistration);
      formData.append("medicalCouncilName", initialValues.medicalCouncilName);
      formData.append("clinics", initialValues.clinics || []);

      // Add e-signature file if available
      if (esignatureFile) {
        formData.append("esignature", esignatureFile);
      }

      // Add specializations as array
      let specilizationsObj = [];
      initialValues.specializations.forEach((spec) => {
        specilizationsObj.push(Object.assign({}, spec));
      });
      formData.append("specializations", JSON.stringify(specilizationsObj));

      // Add qualifications as array
      let qualificationsObj = [];
      initialValues.qualifications.forEach((qual) => {
        qualificationsObj.push(Object.assign({}, qual));
      });
      formData.append("qualifications", JSON.stringify(qualificationsObj));

      // Add multiple files
      if (registrationDocs.length > 0) {
        registrationDocs.forEach((file, index) => {
          formData.append(`registrationdocs`, file);
        });
      }

      if (identityProofs.length > 0) {
        identityProofs.forEach((file, index) => {
          formData.append(`identityproofs`, file);
        });
      }

      if (specializationDocs.length > 0) {
        specializationDocs.forEach((file, index) => {
          formData.append(`specializationdocs`, file);
        });
      }

      if (qualificationDocs.length > 0) {
        qualificationDocs.forEach((file, index) => {
          formData.append(`qualificationdocs`, file);
        });
      }

      formData.append("id", doctorId);
      formData.clinics = [];
      const url = `${apiEndPoint}/doctors/update-doctor`;
      const method = "PUT";

      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (data.msg === "Doctor information updated successfully") {
        toast.success("Doctor updated successfully");
        setTimeout(() => navigate("/doctorsList"), 2000);
      } else {
        toast.error(data.message || "Failed to update doctor");
      }
    } catch (error) {
      console.error("Error updating doctor:", error);
      toast.error("Failed to update doctor");
    }
  };

  if (loading) {
    return (
      <div className="dashboard">
        <Sidebar isActive={isActive} />
        <div className="dashboardContainer">
          <Navbar
            isActive={isActive}
            handleChange={() => setIsActive(!isActive)}
          />
          <div className="form-wrapper">
            <div className="loading-container">
              <p>Loading doctor data...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Sidebar isActive={isActive} />
      <div className="dashboardContainer">
        <Navbar
          isActive={isActive}
          handleChange={() => setIsActive(!isActive)}
        />
        <div className="form-wrapper doctor-info">
          <form className="form-container-doctor" onSubmit={handleSubmit}>
            <h5 className="form-heading">Update Doctor</h5>

            {/* Basic Information */}
            <div className="form-section doctor-basic-info">
              <h6>Basic Information</h6>

              <div className="form-group doctor-name">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={initialValues.name}
                  onChange={handleInputChange}
                  placeholder="Enter Name"
                />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>

              <div className="form-group doctor-email">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={initialValues.email}
                  onChange={handleInputChange}
                  placeholder="Enter Email"
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              <div className="form-group doctor-phone">
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={initialValues.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Enter Phone Number"
                />
                {errors.phoneNumber && (
                  <div className="error">{errors.phoneNumber}</div>
                )}
              </div>

              <div className="form-group doctor-bio">
                <label htmlFor="bio">Bio:</label>
                <textarea
                  id="bio"
                  name="bio"
                  value={initialValues.bio}
                  onChange={handleInputChange}
                  placeholder="Enter Bio"
                />
                {errors.bio && <div className="error">{errors.bio}</div>}
              </div>

              <div className="form-group doctor-gender">
                <label>Gender:</label>
                <div>
                  <input
                    className="gender-input"
                    type="radio"
                    name="gender"
                    value="male"
                    checked={selectedGender === "male"}
                    onChange={handleGenderChange}
                  />
                  <span className="gender-span">Male</span>

                  <input
                    className="gender-input"
                    type="radio"
                    name="gender"
                    value="female"
                    checked={selectedGender === "female"}
                    onChange={handleGenderChange}
                  />
                  <span className="gender-span">Female</span>
                </div>
                {errors.gender && <div className="error">{errors.gender}</div>}
              </div>

              <div className="form-group  doctor-age">
                <label htmlFor="age">Age:</label>
                <input
                  type="text"
                  id="age"
                  name="age"
                  value={initialValues.age}
                  onChange={handleInputChange}
                  placeholder="Enter Age"
                />
                {errors.age && <div className="error">{errors.age}</div>}
              </div>

              <div className="form-group  doctor-total-exp">
                <label htmlFor="experience">Experience (years):</label>
                <input
                  type="text"
                  id="experience"
                  name="experience"
                  value={initialValues.experience}
                  onChange={handleInputChange}
                  placeholder="Enter Experience"
                />
                {errors.experience && (
                  <div className="error">{errors.experience}</div>
                )}
              </div>
            </div>

            {/* Consultation Information Component */}
            <ConsultationInformation
              initialValues={initialValues}
              handleInputChange={handleInputChange}
              isOfflineAvailable={isOfflineAvailable}
              isOnlineAvailable={isOnlineAvailable}
              handleCheckbox={handleCheckbox}
              errors={errors}
            />

            {/* Registration Information Component */}
            <RegistrationInformation
              initialValues={initialValues}
              handleInputChange={handleInputChange}
              errors={errors}
            />

            {/* E-Signature Component */}
            <ESignature
              esignatureFile={esignatureFile}
              handleFileChange={handleFileChange}
              errors={errors}
              esignaturePreview={esignaturePreview}
              removeUploadedFile={removeUploadedFile}
            />

            {/* Specializations Component */}
            <DoctorSpecializations
              specializations={initialValues.specializations}
              specializationList={specializationList}
              handleArrayInputChange={handleArrayInputChange}
              addSpecialization={addSpecialization}
              removeSpecialization={removeSpecialization}
              specializationDocs={specializationDocs}
              handleFileChange={handleFileChange}
              errors={errors}
              uploadedSpecializationFiles={uploadedSpecializationFiles}
              removeUploadedFile={removeUploadedFile}
            />

            {/* Qualifications Component */}
            <DoctorQualifications
              qualifications={initialValues.qualifications}
              qualificationsList={QualificationsList}
              handleArrayInputChange={handleArrayInputChange}
              addQualification={addQualification}
              removeQualification={removeQualification}
              qualificationDocs={qualificationDocs}
              handleFileChange={handleFileChange}
              errors={errors}
              uploadedQualificationFiles={uploadedQualificationFiles}
              removeUploadedFile={removeUploadedFile}
            />

            {/* Document Uploads Component */}
            <DoctorDocuments
              registrationDocs={registrationDocs}
              identityProofs={identityProofs}
              handleFileChange={handleFileChange}
              errors={errors}
              uploadedRegistrationFiles={uploadedRegistrationFiles}
              uploadedIdentityFiles={uploadedIdentityFiles}
              removeUploadedFile={removeUploadedFile}
            />

            <div className="button-container">
              <button type="submit" className="button-savecls">
                Update
              </button>
              <button
                type="button"
                className="button-cancle-cls"
                onClick={() => navigate("/doctorsList")}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditDoctor;
