import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { slotsData } from "../../AdminPanel/data/data";
import CreateSlotPage from "./CreateSlotPage";
import UpdateSlotPage from "./UpdateSlotPage.js";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import '../TeleHealth.css';
import { Link } from "react-router-dom";
import BulkSlotPage from "./BulkSlotPage.js";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteSlot from "./DeleteSlot";
import { Stack } from "@mui/material";
import { API_URL } from "../../../config";

function SlotPage(props) {
    const { setSelectedRowItem } = props;
    
    const [slotdates, setSlotsDate] = useState([]);
    const [showAddMode, setShowAddMode] = useState(false);
    const [showEditMode, setShowEditMode] = useState(false);
    const [showBulkMode, setShowBulkMode] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [getReloadData, setGetReloadData] = useState(false);
    const [showDeleteFlag, setShowDeleteFlag] = useState(false);
    const [slotID, setSlotID] = useState("");
    const [deleteSlotDate, setDeleteSlotDate] = useState('');
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [startTime, setStartTime] = useState(dayjs().startOf('day').add(9, 'hour'));
    const [endTime, setEndTime] = useState(dayjs().startOf('day').add(17, 'hour'));

    const params = useParams();
    const navigate = useNavigate();

    const apiEndPoint = API_URL;
    const [initialValues, setInitialValues] = useState({
        clinicId: "",        
        timeZone: "",
    });

    const doctorName = params.name;
    const doctorId = params.docId;
    const cid = params.cid;    

    function formatDate(date) {
        if (!date) return '';
        const d = dayjs(date);
        return d.format('M/D/YYYY');
    }

    const fetchSlots = async (date) => {
        const url = `${apiEndPoint}/slots/get-slots?search=&page=${1}&pageSize=${10}`;

        const formData = {
            slotDate: date || formatDate(selectedDate),
            doctorId: doctorId,                
            "timeZone": "Asia/Kolkata",                                 
        };

        const token = localStorage.getItem('accessToken');

        const options = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.msg === 'Success') {
                setSlotsDate(data.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchSlots();
    }, [selectedDate, doctorId]);

    useEffect(() => {
        fetchSlots();
    }, []);

    useEffect(() => {
        if (getReloadData) {
            fetchSlots();
            setGetReloadData(false);
        }
    }, [getReloadData]);

    const handleAllSlots = () => {
        setSelectedDate(null);
        fetchSlots("");
    };

    const handleAddSlot = () => {
        setShowAddMode(true);
    };

    const handleEditSlot = () => {
        setShowEditMode(true);
    };

    const handleBulkSlot = () => {
        setShowBulkMode(true);
    };

    const handleChange = () => {
        setIsActive(!isActive);
    }

    const formatTimeToAMPM = (dateTimeStr) => {
        try {
            const date = new Date(dateTimeStr);
            return date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            }).replace(/\s/g, ' '); // Ensure consistent spacing
        } catch (error) {
            return '';
        }
    }

    const handleDeleteSlot = (slot) => {
        setShowDeleteFlag(true);
        setDeleteSlotDate(slot.slotStartTime);
        setSlotID(slot.id);
    };

    const handleDeleteComplete = () => {
        setShowDeleteFlag(false);
        fetchSlots();
    };

    const showNewSlotFormate = (slots) => {
        if (!Array.isArray(slots) || slots.length === 0) {
            return <div>No slots available</div>;
        }

        // Group all slots by time period only
        const groupedSlots = {
            morning: [],
            afternoon: [],
            evening: []
        };

        // Sort all slots into their respective time periods
        slots.forEach(slot => {
            if (!slot.slotStartTime) return;

            const time = new Date(slot.slotStartTime);
            const hours = time.getHours();

            if (hours >= 9 && hours < 12) {
                groupedSlots.morning.push(slot);
            } else if (hours >= 12 && hours < 16) {
                groupedSlots.afternoon.push(slot);
            } else if (hours >= 16 && hours < 22) {
                groupedSlots.evening.push(slot);
            }
        });

        const renderTimeSlots = (slots) => {
            return slots.map(slot => (
                <div 
                    key={slot.id}
                    style={{
                        padding: '8px 12px',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        backgroundColor: slot.appointmentId ? '#f0f0f0' : 'white',
                        minWidth: '100px',
                        justifyContent: 'space-between'
                    }}
                >
                    <span>{formatTimeToAMPM(slot.slotStartTime)}</span>
                    {!slot.appointmentId && (
                        <CancelIcon 
                            style={{ 
                                cursor: 'pointer', 
                                fontSize: '18px',
                                color: '#ff4444'
                            }}
                            onClick={() => handleDeleteSlot(slot)}
                        />
                    )}
                </div>
            ));
        };

        return (
            <div style={{ 
                border: '1px solid #ddd',
                borderRadius: '8px',
                padding: '15px'
            }}>
                {groupedSlots.morning.length > 0 && (
                    <div style={{ marginBottom: '20px' }}>
                        <h4 style={{ 
                            color: '#333', 
                            marginBottom: '10px',
                            backgroundColor: '#f5f5f5',
                            padding: '8px',
                            borderRadius: '4px'
                        }}>Morning (9:00 AM - 12:00 PM)</h4>
                        <div style={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: '10px',
                            padding: '0 10px'
                        }}>
                            {renderTimeSlots(groupedSlots.morning)}
                        </div>
                    </div>
                )}

                {groupedSlots.afternoon.length > 0 && (
                    <div style={{ marginBottom: '20px' }}>
                        <h4 style={{ 
                            color: '#333', 
                            marginBottom: '10px',
                            backgroundColor: '#f5f5f5',
                            padding: '8px',
                            borderRadius: '4px'
                        }}>Afternoon (12:00 PM - 4:00 PM)</h4>
                        <div style={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: '10px',
                            padding: '0 10px'
                        }}>
                            {renderTimeSlots(groupedSlots.afternoon)}
                        </div>
                    </div>
                )}

                {groupedSlots.evening.length > 0 && (
                    <div style={{ marginBottom: '20px' }}>
                        <h4 style={{ 
                            color: '#333', 
                            marginBottom: '10px',
                            backgroundColor: '#f5f5f5',
                            padding: '8px',
                            borderRadius: '4px'
                        }}>Evening (4:00 PM - 10:00 PM)</h4>
                        <div style={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: '10px',
                            padding: '0 10px'
                        }}>
                            {renderTimeSlots(groupedSlots.evening)}
                        </div>
                    </div>
                )}
            </div>
        );
    };
    
    return (
        <div className='dashboard'>
            <Sidebar isActive={isActive} />
            <div className='dashboardContainer'>
                <Navbar isActive={isActive} handleChange={handleChange} />
                <div className="page-container" style={{margin: '10px 10px', maxWidth: '100%'}}>
                    <header className="page-header">
                        <div style={{display:'flex',width:'100%'}}>
                            <div style={{width:'60%'}}>
                                <span>Slots for <b>Dr. {doctorName}</b></span>
                            </div>
                            <div style={{width:'40%',textAlign: 'right'}}>
                                <button className="add-slot-button" onClick={handleAddSlot}>Add Slot</button>&nbsp;
                                <button className="add-slot-button" onClick={handleEditSlot}>Edit Slot</button>&nbsp;
                                <button className="add-slot-button" onClick={handleBulkSlot}>Bulk Slot</button>
                            </div>
                        </div>                        
                    </header>                 

                    <div className="date-picker-container">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: 2, mt: 2 }}>
                                <DatePicker
                                    label="Select Date"
                                    value={selectedDate}
                                    onChange={(newDate) => setSelectedDate(newDate)}
                                    sx={{ width: 200 }}
                                />
                                <TimePicker
                                    label="Start Time"
                                    value={startTime}
                                    onChange={(newTime) => setStartTime(newTime)}
                                    sx={{ width: 150 }}
                                />
                                <TimePicker
                                    label="End Time"
                                    value={endTime}
                                    onChange={(newTime) => setEndTime(newTime)}
                                    sx={{ width: 150 }}
                                />
                                <button className="add-slot-button" onClick={handleAllSlots}>All Slots</button>
                            </Stack>
                        </LocalizationProvider>
                    </div>
                    <br />

                    <div className="slot-container">
                        <h4>Slots Info:</h4>
                        <br /> 
                        {showNewSlotFormate(slotdates)}

                        <Dialog 
                            header="Add Slot Information"
                            visible={showAddMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowAddMode(false)}
                        >
                            <CreateSlotPage 
                                setGetReloadData={setGetReloadData} 
                                doctorName={doctorName}
                                doctorId={doctorId} 
                                cId={cid} 
                                setUserAdded={() => {
                                    setShowAddMode(false);
                                }}
                            />
                        </Dialog>

                        <Dialog 
                            header="Edit Slot Information"
                            visible={showEditMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowEditMode(false)}
                        >
                            <UpdateSlotPage 
                                setGetReloadData={setGetReloadData} 
                                setUserEdited={() => {
                                    setShowEditMode(false);
                                }}
                            />
                        </Dialog>

                        <Dialog 
                            header="Bulk Slot Information"
                            visible={showBulkMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowBulkMode(false)}
                        >
                            <BulkSlotPage 
                                setGetReloadData={setGetReloadData} 
                                doctorId={doctorId}
                                doctorName={doctorName}
                                clinicId={cid}
                                setUserBulked={() => {
                                    setShowBulkMode(false);
                                }}
                            />
                        </Dialog>

                        <Dialog 
                            header="Delete Slot"
                            visible={showDeleteFlag}
                            style={{ width: '39vw' }}
                            onHide={() => setShowDeleteFlag(false)}
                        >
                            <DeleteSlot 
                                slotID={slotID} 
                                deleteSlotDate={deleteSlotDate} 
                                doctorName={doctorName}
                                doctorId={doctorId} 
                                cId={cid} 
                                setSlotDelete={handleDeleteComplete}  
                            />
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlotPage;
