import React, { useState, useEffect } from 'react';
import '../TeleHealth.css';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CreateDistrict from './CreateDistrict.js';
import UpdateDistrict from './UpdateDistrict.js';
import { Tag } from 'primereact/tag';
import { API_URL } from "../../../config";

export default function DistrictsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState([]);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [showCreateForm, setShowCreateForm] = useState(false);
	const [getReloadData, setGetReloadData] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const apiEndPoint = API_URL;

	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
			default:
				return '';
		}
	};

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/districtsList');
		} else {
			navigate('/')
		}
	}, [UAToken, navigate]);

	useEffect(() => {
		getDistrictsList();
	}, [searchTerm]);

	useEffect(() => {
		if (getReloadData) {
			getDistrictsList();
			setGetReloadData(false);
		}
	}, [getReloadData]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	const getDistrictsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/get-districts-list?search=${searchTerm}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching districts:', error);
			toast.error("Failed to load districts");
			if (error.message.includes('401')) {
				navigate('/login');
			}
		}
	};

	const deleteConfirm = (districtId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this district?',
			header: 'Confirmation',
			accept: () => deleteDistrict(districtId),
		});
	}

	const deleteDistrict = async (id) => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/delete-district/${id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error('Failed to delete district');
			}

			const data = await response.json();
			if (data.msg === "District deleted successfully") {
				toast.success("District deleted successfully");
				getDistrictsList();
			} else {
				toast.error(data.message || "Failed to delete district");
			}
		} catch (error) {
			console.error('Error deleting district:', error);
			toast.error("Failed to delete district");
		}
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button 
					icon="pi pi-pencil" 
					title='Edit' 
					rounded 
					outlined 
					className="mr-2 editBtnFocus" 
					onClick={() => {
						setSelectedRowItem(rowData)
						setShowEditMode(true)
					}}
				/>
				<Button 
					icon="pi pi-trash" 
					title='Delete' 
					rounded 
					outlined 
					className="deleteBtnFocus" 
					onClick={() => deleteConfirm(rowData.id)}
				/>
			</React.Fragment>
		);
	};

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div>
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='list-header'>
									<div className='header-users'>
										<h6>Districts</h6>
									</div>
									<div className='header-actions'>
										<span className="p-input-icon-left search-input">
											<i className="pi pi-search" />
											<InputText 
												placeholder="Search districts" 
												value={searchTerm}
												onChange={handleSearch}
											/>
										</span>
										<button className='addUserCls' title='Add District' onClick={() => setShowCreateForm(true)}>
											<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
										</button>
									</div>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column 
										field="name" 
										header="Name" 
										sortable 
										filter
										style={{ width: '30%' }}
									/>
									<Column 
										field="code" 
										header="Code" 
										sortable 
										filter
										style={{ width: '30%' }}
									/>
									<Column 
										field="status" 
										header="Status" 
										body={statusBodyTemplate} 
										sortable 
										filter
										style={{ width: '20%' }}
									/>
									<Column 
										header="Actions" 
										body={actionBodyTemplate}
										style={{ width: '20%' }}
									/>
								</DataTable>
							</div>
						</div>
					</div>

					<Dialog 
						header="Create New District"
						visible={showCreateForm}
						style={{ width: '40vw' }}
						onHide={() => setShowCreateForm(false)}
					>
						<CreateDistrict 
							visible={showCreateForm} 
							userId={selectedUserId} 
							selectedRowItem={selectedRowItem} 
							setGetReloadData={setGetReloadData} 
							setDistrictAdd={() => setShowCreateForm(false)} 
						/>
					</Dialog>

					<Dialog 
						header="Update District"
						visible={showEditMode}
						style={{ width: '40vw' }}
						onHide={() => setShowEditMode(false)}
					>
						<UpdateDistrict 
							visible={showEditMode} 
							userId={selectedUserId} 
							updateData={selectedRowItem} 
							updateDateId={selectedRowId} 
							setGetReloadData={setGetReloadData} 
							setUserEdited={() => setShowEditMode(false)} 
						/>
					</Dialog>

					<ConfirmDialog />
					<ToastContainer />
				</div>
			</div>
		</div>
	);
}
