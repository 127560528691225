import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from "primereact/inputswitch";
import { API_URL } from "../../../config";

function UpdateSymptom(props) {
  const { updateData, setGetReloadData } = props;
  const updateStatus =
    updateData && updateData?.status === "Active"
      ? true
      : updateData && updateData?.status === "In-Active"
      ? false
      : false;

  const [errorSymptomNameMessage, setErrorSymptomNameMessage] = useState("");
  const [errorSpecializationIdMessage, setErrorSpecializationIdMessage] =
    useState("");

  const [initialValues, setInitialValues] = useState({
    symptom: updateData ? updateData?.symptom : "",
    specializationId: updateData ? updateData?.specializationId : "",
    image: updateData ? updateData?.image : "",
    status: updateData ? updateData?.status : "",
  });

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [specializationList, setSpecializationList] = useState([]);
  const [questStatus, setquestStatus] = useState(updateStatus);
  const navigate = useNavigate();
  const apiEndPoint = API_URL;

  useEffect(() => {
    getSpecializations();
  }, []);

  const getSpecializations = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${apiEndPoint}/specializations/get-specializations-list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        // localStorage.setItem("accessToken","");
        // navigate('/login');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const actualData = await response.json();
      setSpecializationList(actualData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const UpdateSymptomData = async () => {
    console.log("file", file);
    if (initialValues.symptom === "" || initialValues.specializationId === "") {
      validationForm("symptom", initialValues.symptom);
      validationForm("specializationId", initialValues.specializationId);
    } else {
      const url = `${apiEndPoint}/symptoms/update-symptom`;
      //const reader = new FileReader();
      //reader.readAsArrayBuffer(file);

      //reader.onloadend = async () => {
      // 	const arrayBuffer = reader.result;
      // 	const binaryString = Array.from(new Uint8Array(arrayBuffer))
      // 		.map(byte => String.fromCharCode(byte))
      // 		.join('')

      // 	const formData = {
      // 		id: updateData?.id,
      // 		symptom: initialValues.symptom,
      // 		image: binaryString,
      // 		specializationId: initialValues.specializationId,
      // 		status: questStatus === true ? 'Active' : 'In-Active'
      // 	};

      var bodyFormData = new FormData();
      bodyFormData.append("id", updateData?.id);
      bodyFormData.append("symptom", initialValues?.symptom);
      bodyFormData.append("specializationId", initialValues?.specializationId);
      bodyFormData.append(
        "status",
        questStatus === true ? "Active" : "In-Active"
      );
      bodyFormData.append("image", file, file.name);

      const token = localStorage.getItem("accessToken");

      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          //'Content-Type': 'application/json',
          //'Content-Type': 'multipart/form-data',
          //'Content-Type': 'undefined'
        },
        body: bodyFormData,
      };

      // const options = {
      // 	method: 'PUT',
      // 	headers: {
      // 		'Authorization': `Bearer ${token}`,
      // 		'Content-Type': 'application/json',
      // 	},
      // 	body: JSON.stringify(formData),
      // };

      await fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.msg === "Symptom details updated successfully") {
            setInitialValues({
              ...initialValues,
              symptom: "",
              specializationId: "",
              image: "",
              status: "",
            });
            setFile(null);
            setTimeout(() => {
              navigate("/symptomsList");
            }, 2000);
            props.setUserEdited();
            setGetReloadData(true);
            toast.success("Symptom details updated successfully");
          }
        })

        .catch((error) => console.error("Error:", error));
    }
    //}
  };

  const validationForm = (type, value) => {
    let errorMessage = "";
    const newErrors = {};
    if (type === "symptom") {
      if (value === "") {
        errorMessage = "Enter Symptom Name ";
      } else if (value.length < 3) {
        errorMessage = "Please enter atleast 3 Characters";
      }

      setErrorSymptomNameMessage(errorMessage);
      setAllErrors({ ...allErrors, ["symptomNameErr"]: errorMessage });
    }
    if (type === "specializationId") {
      if (value === "") {
        errorMessage = "Select Specialization Id";
      }
      setErrorSpecializationIdMessage(errorMessage);
      setAllErrors({ ...allErrors, ["specializationIdErr"]: errorMessage });
    }
    if (!file) {
      newErrors.image = "Please upload an image.";
    }
    setErrors(newErrors);
    return errorMessage;
  };

  const [allErrors, setAllErrors] = useState({
    symptomNameErr: "",
    specializationIdErr: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validationForm(name, value);
  };

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("selectedFile", selectedFile);
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/png", "image/svg+xml"];
      const maxSize = 4 * 1024 * 1024; // 2MB
      if (!validTypes.includes(selectedFile.type)) {
        setErrors({
          ...errors,
          image: "Please upload an image in JPEG or PNG format.",
        });
        return;
      }
      if (selectedFile.size > maxSize) {
        setErrors({
          ...errors,
          image: "Please upload an image smaller than 2MB.",
        });
        return;
      }
      setErrors({ ...errors, image: "" });
      setFile(selectedFile);
    }
  };

  const handleChangeSwitch = (e) => {
    console.log(!questStatus);
    setquestStatus(!questStatus);
  };

  return (
    <div className="user-view _add-view">
      <div className="box">
        <div className="row">
          <div>
            <p>
              <span>Symptom Name</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Symptom Name"
                name="symptom"
                value={initialValues.symptom}
                onChange={handleInputChange}
              />
              <div className="error">{errorSymptomNameMessage}</div>
            </p>
          </div>
          <div>
            <p>
              <label>Specialization Id</label>
              <select
                className="doctorsListCls form-control"
                name="specializationId"
                value={initialValues.specializationId}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {specializationList.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.specialization}
                  </option>
                ))}
              </select>
              <div className="error">{errorSpecializationIdMessage}</div>
            </p>
          </div>
          <div>
            <p>
              <label>Image</label>
              <br />
              <input type="file" onChange={handleChange} />
              <div className="error">{errors.image}</div>
            </p>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ paddingLeft: "2px" }}>Status</div>
            <div style={{ paddingLeft: "10px" }}>
              <InputSwitch
                inputId="input-metakey"
                checked={questStatus}
                onChange={handleChangeSwitch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="specialBtnCls button-container">
        <button
          type="button"
          className="button-cancle-cls"
          style={{ marginRight: "15px" }}
          onClick={() => props.setUserEdited()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="button-savecls"
          onClick={() => UpdateSymptomData()}
        >
          Update
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default UpdateSymptom;
