import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../AdminPanel/style.css';
import { Link } from '@mui/material';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { dashboardUsers } from "../../AdminPanel/data/data.js";
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CreateSachivalayam from './CreateSachivalayam.js';
import { Tag } from 'primereact/tag';
import UpdateSachivalayam from './UpdateSachivalayam.js';
import { API_URL } from "../../../config";

export default function SachivalayamsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [sachivalayamsList, setSachivalayamsList] = useState([]);
	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState([]);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [showCreateForm, setShowCreateForm] = useState(false);
	const [getReloadData, setGetReloadData] = useState(false);
	
	const apiEndPoint = API_URL;

	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
		}
	};

	useEffect(() => {
		setSachivalayamsList(dashboardUsers)
	}, []);

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			getSachivalayamsList();
		}
	}, [searchTerm]);

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/sachivalayamsList');
		}
		else {
			navigate('/')
		}
	}, [UAToken]);

	const getSachivalayamsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/sachivalayams/get-sachivalayams-list?search=${searchTerm}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {
		if (getReloadData) {
			getSachivalayamsList();
			setGetReloadData(false);
		}
	}, [getReloadData]);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	const deleteConfirm = (slotId) => {
		confirmDialog({
			message: 'Are you sure you want to delete sachivalayam?',
			header: 'Confirmation',
			accept: () => deleteSachivalayam(slotId),
		});
	}

	const handleChange = () => {
		setIsActive(!isActive);
	}

	const deleteSachivalayam = async (Id) => {
		try {
			const token = localStorage.getItem('accessToken');
			await fetch(`${apiEndPoint}/sachivalayams/delete-sachivalayam/${Id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			})
				.then(response => response.json())
				.then(actualData => {
					if (actualData.msg === "Sachivalayam deleted successfully") {
						toast.success("Sachivalayam deleted successfully");
						getSachivalayamsList();
					}
				})
				.catch(err => {
					console.log(err.message);
				});
		}
		catch (e) {
			console.log(e)
		}
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-pencil" title='Edit' rounded outlined className="mr-2 editBtnFocus" onClick={() => {
					setSelectedRowItem(rowData)
					setShowEditMode(true)
				}} />
				<Button icon="pi pi-trash" className='deleteBtnFocus' title='Delete' rounded outlined onClick={() => deleteConfirm(rowData.id)} />
			</React.Fragment>
		);
	};

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div>
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='list-header'>
									<div className='header-users'>
										<h6>Sachivalayams</h6>
									</div>
									<div className='header-actions'>
										<span className="p-input-icon-left search-input">
											<i className="pi pi-search" />
											<InputText 
												placeholder="Search sachivalayams" 
												value={searchTerm}
												onChange={handleSearch}
											/>
										</span>
										<button className='addUserCls' title='Add Sachivalayam' onClick={() => setShowCreateForm(true)}>
											<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
										</button>
									</div>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column field='name' header="Name" sortable style={{ width: '15%', marginLeft: "5px" }} filter></Column>
									<Column field='code' header="Code" sortable style={{ width: '10%', marginLeft: "5px" }} filter></Column>
									<Column field='mandalName' header="Mandal" sortable style={{ width: '15%', marginLeft: "5px" }} filter></Column>
									<Column field='districtName' header="District" sortable style={{ width: '15%', marginLeft: "5px" }} filter></Column>
									<Column field='anmName' header="ANM Name" sortable style={{ width: '15%', marginLeft: "5px" }} filter></Column>
									<Column field='anmPhoneNumber' header="ANM Phone" sortable style={{ width: '15%', marginLeft: "5px" }} filter></Column>
									<Column style={{ width: '15%' }} header="Actions" body={actionBodyTemplate}></Column>
								</DataTable>
							</div>
						</div>
					</div>
					<Dialog header="Create New Sachivalayam"
						visible={showCreateForm}
						style={{ width: '40vw' }}
						onHide={() => setShowCreateForm(false)}>
						<CreateSachivalayam visible={showCreateForm} userId={selectedUserId} selectedRowItem={selectedRowItem} setGetReloadData={setGetReloadData} setSachivalayamAdd={() => {
							setShowCreateForm(false);
						}} />
					</Dialog>
					<Dialog header="Update Sachivalayam"
						visible={showEditMode}
						style={{ width: '39vw' }}
						onHide={() => setShowEditMode(false)}>
						<UpdateSachivalayam visible={showEditMode} userId={selectedUserId} updateData={selectedRowItem} updateDateId={selectedRowId} setGetReloadData={setGetReloadData} setUserEdited={() => {
							setShowEditMode(false);
						}} />
					</Dialog>
					<ConfirmDialog />
					<ToastContainer />
				</div>
			</div>
		</div>
	)
}
