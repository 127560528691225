import React, { useEffect, useState } from "react";
import "../../AdminPanel/style.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SpecializationIcon from "@mui/icons-material/StarHalf";
import SymptomIcon from "@mui/icons-material/AcUnit";
import MandalIcon from "@mui/icons-material/Equalizer";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { Link, useLocation } from "react-router-dom";
import teleHelath_Img from "../../TeleHealth/Images/telehealth_Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLandmark,
  faSchoolFlag,
  faStethoscope,
  faUserMd,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import QualificationIcon from "@mui/icons-material/School";

const Sidebar = (props) => {
  const { isActive } = props;
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));
  const location = useLocation();

  useEffect(() => {
    const handleStorageChange = () => {
      setUserRole(localStorage.getItem("userRole"));
    };

    window.addEventListener("storage", handleStorageChange);

    // Also check on mount
    handleStorageChange();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className={isActive ? "sidebar_darkMode" : "sidebar"}>
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="logo-container">
            <img
              className="logo-image"
              src={teleHelath_Img}
              alt="TeleHealth Logo"
            />
          </div>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <li className={location.pathname === "/dashboard" ? "active" : ""}>
              <DashboardIcon className="iconCls" />
              <span>Dashboard</span>
            </li>
          </Link>
          {userRole === "admin" && (
            <>
              <Link to="/clinicsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/clinicsList" ? "active" : ""
                  }
                >
                  <FontAwesomeIcon icon={faStethoscope} className="iconCls" />
                  <span>Clinics</span>
                </li>
              </Link>
              <Link
                to="/specializationsList"
                style={{ textDecoration: "none" }}
              >
                <li
                  className={
                    location.pathname === "/specializationsList" ? "active" : ""
                  }
                >
                  <SpecializationIcon className="iconCls" />
                  <span>Specializations</span>
                </li>
              </Link>
              <Link to="/symptomsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/symptomsList" ? "active" : ""
                  }
                >
                  <SymptomIcon className="iconCls" />
                  <span>Symptoms</span>
                </li>
              </Link>
              <Link to="/qualificationsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/qualificationsList" ? "active" : ""
                  }
                >
                  <QualificationIcon className="iconCls" />
                  <span>Qualifications</span>
                </li>
              </Link>
              <Link to="/mandalsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/mandalsList" ? "active" : ""
                  }
                >
                  <MandalIcon className="iconCls" />
                  <span>Mandals</span>
                </li>
              </Link>
              <Link to="/districtsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/districtsList" ? "active" : ""
                  }
                >
                  <FontAwesomeIcon icon={faSchoolFlag} className="iconCls" />
                  <span>Districts</span>
                </li>
              </Link>
              <Link to="/sachivalayamsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/sachivalayamsList" ? "active" : ""
                  }
                >
                  <FontAwesomeIcon icon={faLandmark} className="iconCls" />
                  <span>Sachivalayams</span>
                </li>
              </Link>
              <Link to="/couponsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/couponsList" ? "active" : ""
                  }
                >
                  <LocalOfferIcon className="iconCls" />
                  <span>Coupons</span>
                </li>
              </Link>
              {/* <Link
                to="/prescriptionOrdersList"
                style={{ textDecoration: "none" }}
              >
                <li
                  className={
                    location.pathname === "/prescriptionOrdersList"
                      ? "active"
                      : ""
                  }
                >
                  <MedicalServicesIcon className="iconCls" />
                  <span>Prescription Orders</span>
                </li>
              </Link> */}
              <Link to="/pendingDoctorsList" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/pendingDoctorsList" ? "active" : ""
                  }
                >
                  <FontAwesomeIcon icon={faUserMd} className="iconCls" />
                  <span>Pending Doctors</span>
                </li>
              </Link>
              <Link to="/telehealth-doctors" style={{ textDecoration: "none" }}>
                <li
                  className={
                    location.pathname === "/telehealth-doctors" ? "active" : ""
                  }
                >
                  <FontAwesomeIcon icon={faUserDoctor} className="iconCls" />
                  <span>Telehealth Doctors</span>
                </li>
              </Link>
            </>
          )}
          {(userRole === "admin" || userRole === "ciai doctor") && (
            <Link
              to="/prescriptionOrdersList"
              style={{ textDecoration: "none" }}
            >
              <li
                className={
                  location.pathname === "/prescriptionOrdersList"
                    ? "active"
                    : ""
                }
              >
                <MedicalServicesIcon className="iconCls" />
                <span>Prescription Orders</span>
              </li>
            </Link>
          )}
          {userRole === "clinic" && (
            <Link to="/doctorsList" style={{ textDecoration: "none" }}>
              <li
                className={location.pathname === "/doctorsList" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faUserMd} className="iconCls" />
                <span>Doctors</span>
              </li>
            </Link>
          )}

          <Link to="/logout" style={{ textDecoration: "none" }}>
            <li className={location.pathname === "/logout" ? "active" : ""}>
              <LogoutIcon className="iconCls" />
              <span>Logout</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
