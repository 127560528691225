import React, { useState, useEffect } from 'react';
import '../TeleHealth.css';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CreateMandal from './CreateMandal.js';
import UpdateMandal from './UpdateMandal.js';
import { Tag } from 'primereact/tag';
import { API_URL } from "../../../config";

export default function MandalsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState([]);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [showCreateForm, setShowCreateForm] = useState(false);
	const [getReloadData, setGetReloadData] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const apiEndPoint = API_URL;

	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
			default:
				return '';
		}
	};

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/mandalsList');
		} else {
			navigate('/')
		}
	}, [UAToken, navigate]);

	useEffect(() => {
		getMandalsList();
	}, [searchTerm]);

	useEffect(() => {
		if (getReloadData) {
			getMandalsList();
			setGetReloadData(false);
		}
	}, [getReloadData]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	const getMandalsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/mandals/get-mandals-list?search=${searchTerm}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching mandals:', error);
			toast.error("Failed to load mandals");
			if (error.message.includes('401')) {
				navigate('/login');
			}
		}
	};

	const deleteConfirm = (mandalId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Mandal?',
			header: 'Confirmation',
			accept: () => deleteMandal(mandalId),
		});
	}

	const deleteMandal = async (id) => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/mandals/delete-mandal/${id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				throw new Error('Failed to delete mandal');
			}

			const data = await response.json();
			if (data.msg === "Mandal deleted successfully") {
				toast.success("Mandal deleted successfully");
				getMandalsList();
			} else {
				toast.error(data.message || "Failed to delete mandal");
			}
		} catch (error) {
			console.error('Error deleting mandal:', error);
			toast.error("Failed to delete mandal");
		}
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button 
					icon="pi pi-pencil" 
					title='Edit' 
					rounded 
					outlined 
					className="mr-2 editBtnFocus" 
					onClick={() => {
						setSelectedRowItem(rowData)
						setShowEditMode(true)
					}}
				/>
				<Button 
					icon="pi pi-trash" 
					title='Delete' 
					rounded 
					outlined 
					className="deleteBtnFocus" 
					onClick={() => deleteConfirm(rowData.id)}
				/>
			</React.Fragment>
		);
	};

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div>
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='list-header'>
									<div className='header-users'>
										<h6>Mandals</h6>
									</div>
									<div className='header-actions'>
										<span className="p-input-icon-left search-input">
											<i className="pi pi-search" />
											<InputText 
												placeholder="Search mandals" 
												value={searchTerm}
												onChange={handleSearch}
											/>
										</span>
										<button className='addUserCls' title='Add Mandal' onClick={() => setShowCreateForm(true)}>
											<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
										</button>
									</div>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column 
										field="name" 
										header="Name" 
										sortable 
										filter
										style={{ width: '30%' }}
									/>
									<Column 
										field="districtName" 
										header="District" 
										sortable 
										filter
										style={{ width: '30%' }}
									/>
									<Column 
										field="status" 
										header="Status" 
										body={statusBodyTemplate} 
										sortable 
										filter
										style={{ width: '20%' }}
									/>
									<Column 
										header="Actions" 
										body={actionBodyTemplate}
										style={{ width: '20%' }}
									/>
								</DataTable>
							</div>
						</div>
					</div>

					<Dialog 
						header="Create New Mandal"
						visible={showCreateForm}
						style={{ width: '40vw' }}
						onHide={() => setShowCreateForm(false)}
					>
						<CreateMandal 
							visible={showCreateForm} 
							userId={selectedUserId} 
							selectedRowItem={selectedRowItem} 
							setGetReloadData={setGetReloadData} 
							setMandalAdd={() => setShowCreateForm(false)} 
						/>
					</Dialog>

					<Dialog 
						header="Update Mandal"
						visible={showEditMode}
						style={{ width: '40vw' }}
						onHide={() => setShowEditMode(false)}
					>
						<UpdateMandal 
							visible={showEditMode} 
							userId={selectedUserId} 
							updateData={selectedRowItem} 
							setGetReloadData={setGetReloadData} 
							setUserEdited={() => setShowEditMode(false)} 
						/>
					</Dialog>

					<ConfirmDialog />
					<ToastContainer />
				</div>
			</div>
		</div>
	);
}
