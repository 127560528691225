import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../TeleHealth.css';
import './ReviewNotes.css';
import { API_URL } from "../../../config";

function ReviewNotes(props) {
    const { doctorID, setGetReloadData } = props;
    
    const [rejectedReason, setRejectedReason] = useState("");
    const [errorNameMessage, setErrorNameMessage] = useState("");

    const [initialValues, setInitialValues] = useState({
        doctorId: doctorID ? doctorID : '',
        rejectedReason: rejectedReason ? rejectedReason : ''
    });

    const navigate = useNavigate();

    const apiEndPoint = API_URL;

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!!token) {
            // Initialize if needed
        }
    }, []);

    const cancelDoctorData = (e) => {
        navigate('/pendingDoctorsList');
    };
    
    const [allErrors, setAllErrors] = useState({
        nameErr: "",
    });

    const validationForm = (type, value) => {
        let errorMessage = "";
        if (type === "name") {
            if (value === "") {
                errorMessage = "Enter the Rejected Reason";
            }
            
            setErrorNameMessage(errorMessage)
            setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
        }
    }
    
    const rejectedReasonHandle = async () => { 
        if (rejectedReason === '') {
            validationForm('name', initialValues.rejectedReason);
        } else {
            const url = `${apiEndPoint}/doctors/reject-doctor`; 
            
            const formData = {
                doctorId: doctorID,
                rejectedReason: rejectedReason
            };

            const token = localStorage.getItem('accessToken');
            const options = {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            };

            try {
                const response = await fetch(url, options);
                const data = await response.json();

                if (data.msg === 'Doctor Rejected Successfully') {
                    setInitialValues({ ...initialValues, rejectedReason: '' });
                    toast.success("Doctor Rejected Successfully.");
                    props.setUserEdited();
                    setGetReloadData(true);
                    setTimeout(() => {
                        navigate('/pendingDoctorsList');
                    }, 2000);
                } else {                        
                    toast.error("Unable to reject the doctor. This doctor already approved/rejected");
                    setTimeout(() => {
                        navigate('/pendingDoctorsList');
                    }, 2000);
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error("Failed to reject doctor");
            }
        }
    }

    const handleInputChange = (e) => { 
        const { value } = e.target;
        setRejectedReason(value);
        setInitialValues(prevState => ({
            ...prevState,
            rejectedReason: value
        }));
    };

    return (
        <div>
            <form className="form-container-doctor">
                <div className="form-group">
                    <label htmlFor="rejectedReason">Reject Remarks:</label>
                    <textarea
                        id="rejectedReason"
                        name="rejectedReason"
                        value={initialValues.rejectedReason}
                        onChange={handleInputChange}
                        placeholder="Enter reject remarks"
                    />
                    {errorNameMessage && <div className="error">{errorNameMessage}</div>}
                </div>

                <div className="button-container">
                    <button 
                        type="button" 
                        className="button-savecls" 
                        onClick={rejectedReasonHandle}
                    >
                        Submit
                    </button>
                    <button 
                        type="button"
                        className="button-cancle-cls" 
                        onClick={cancelDoctorData}
                    >
                        Cancel
                    </button>
                </div>
                <ToastContainer />
            </form>
        </div>
    );
}

export default ReviewNotes;
