import React from "react";
import "../TeleHealth.css";

function ConsultationInformation({
  initialValues,
  handleInputChange,
  isOfflineAvailable,
  isOnlineAvailable,
  handleCheckbox,
  errors,
}) {
  return (
    <div className="form-section doctor-consult-info">
      <h6>Consultation Information</h6>

      <div className="form-group">
        <label htmlFor="isOfflineConsultationAvailable">
          Offline Consultation Available
        </label>
        <input
          className="consult-input"
          type="checkbox"
          id="isOfflineConsultationAvailable"
          name="isOfflineConsultationAvailable"
          checked={isOfflineAvailable}
          onChange={handleCheckbox("offline")}
        />
        {errors?.offlineCheckbox && (
          <div className="error">{errors.offlineCheckbox}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="isOnlineConsultationAvailable">
          Online Consultation Available
        </label>
        <input
          className="consult-input"
          type="checkbox"
          id="isOnlineConsultationAvailable"
          name="isOnlineConsultationAvailable"
          checked={isOnlineAvailable}
          onChange={handleCheckbox("online")}
        />
        {errors?.onlineCheckbox && (
          <div className="error">{errors.onlineCheckbox}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="consultationFee">Consultation Fee:</label>
        <input
          type="text"
          id="consultationFee"
          name="consultationFee"
          value={initialValues.consultationFee}
          onChange={handleInputChange}
          placeholder="Enter Consultation Fee"
        />
        {errors?.consultationFee && (
          <div className="error">{errors.consultationFee}</div>
        )}
      </div>
    </div>
  );
}

export default ConsultationInformation;
