import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuProps, useStyles, options } from "./utils";
import { useNavigate } from 'react-router-dom';
import '../TeleHealth.css';
import { format } from 'date-fns-tz';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import 'react-datetime-picker/dist/DateTimePicker.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import addDays from 'date-fns/addDays'
import { Stack } from 'rsuite';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { API_URL } from "../../../config";

export default function DeleteSlot(props) {
    const { slotID, deleteSlotDate, doctorId, doctorName, cId, setSlotDelete } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const apiEndPoint = API_URL;
    const [isDeleting, setIsDeleting] = useState(false);

    const formatDateTime = (dateTimeStr) => {
        try {
            const date = new Date(dateTimeStr);
            return date.toLocaleString('en-US', {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            });
        } catch (error) {
            return dateTimeStr;
        }
    };

    const deleteSlotData = async () => {
        if (isDeleting) return; // Prevent multiple clicks
        setIsDeleting(true);

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                toast.error("Authentication token not found. Please login again.");
                return;
            }

            const response = await fetch(`${apiEndPoint}/slots/delete-slot/${slotID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || `Error: ${response.status}`);
            }

            const data = await response.json();
            
            if (data.msg === 'Success' || data.status === 'success') {
                toast.success("Slot deleted successfully");
                // Wait for the toast to be visible before closing
                setTimeout(() => {
                    setSlotDelete();
                }, 1000);
            } else {
                throw new Error(data.message || 'Failed to delete slot');
            }
        } catch (error) {
            console.error('Delete slot error:', error);
            toast.error(error.message || "Failed to delete slot. Please try again.");
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className='create-slot-container'>
            <div>
                <div className="flex-auto" style={{ paddingRight: '10px', marginLeft: '-6px', width: "429px" }}>
                    <label htmlFor="calendar-24h" className="font-bold block mb-2" style={{ marginLeft: "25px" }}>
                        Are you sure you want to delete this slot?
                    </label>
                    <div style={{ marginLeft: "25px", marginTop: "10px", marginBottom: "20px" }}>
                        <strong>Slot Time:</strong> {formatDateTime(deleteSlotDate)}
                    </div>
                </div>
            </div>

            <div style={{ textAlign: 'center', marginLeft: '30px' }}>
                <Stack direction="row" spacing={2}>
                    <Button 
                        className='buttonSlotAlignCls2' 
                        variant="contained" 
                        onClick={deleteSlotData}
                        disabled={isDeleting}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                    <Button 
                        className='buttonSlotAlignCls3' 
                        variant="contained"  
                        onClick={() => setSlotDelete()} 
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                </Stack>
            </div>
            <ToastContainer />
        </div>
    );
}
