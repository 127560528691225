import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { InputSwitch } from 'primereact/inputswitch';
import '../TeleHealth.css';
import { API_URL } from "../../../config";

export default function CreateQuestion(props) {
    const { questInfo, handleClose,setGetReloadData,symptomId,questionData } = props;

    const [initialValues, setInitialValues] = useState({
        QuestionName: '',
        Option1: '',
        Option2: '',
        Option3: '',
        Option4: ''
    });

	//console.log('questionData',questionData)
    const [questionList, setquestionList] = useState([])
    const [questStatus, setquestStatus] = useState(false);

    const navigate = useNavigate();
    const apiEndPoint = API_URL;

	
    const [file, setFile] = useState()

    function handleChange(event) {
        console.log('file', event.target.files[0])
        setFile(event.target.files[0])
    }

	console.log('symptomId---------',symptomId)

    const CreateSymPtomData = async () => {
        const url = `${apiEndPoint}/symptoms/create-question`;
        const formData = {
            symptomId: symptomId,
            question: initialValues.question,
            option1: initialValues.Option1,
            option2: initialValues.Option2,
            option3: initialValues.Option3,
            option4: initialValues.Option4
        };
        const token = localStorage.getItem('accessToken');
        const options = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        };
        await fetch(url, options)
            .then(response => response.json())
            .then(data => {
                if (data.msg === 'reacord created') {
					setInitialValues({
                        ...initialValues,
                        symptom: '',
                        question: '',
                        option1: '',
                        option2: '',
                        option3: '',
                        option4: ''
                    });
                    setTimeout(() => {
                        navigate('/questionsList');
                    }, 2000)
					handleClose();
					setGetReloadData(true);
					toast.success("Question Saved Successfully.");
                }
                else {
                    toast.error('Network server error3.')
                }
            })
            .catch(error => console.error('Error:', error));
    }

    return (
        <div className='user-view _add-view'>
            <div className='box'>
                <div className='row'>
                    <div>
                        <p>
                            <label>Question Name</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Question Name'
                                value={initialValues.question}
                                onChange={e => setInitialValues({ ...initialValues, question: e.target.value })}
                            />
                        </p>
                    </div>
                    <div>
                        <div><label style={{ fontWeight: 'bold', fontSize: '19px' }}>Options</label></div>&nbsp;
                        <p>
                            <label>Option1</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter the Option1'
                                value={initialValues.Option1}
                                onChange={e => setInitialValues({ ...initialValues, Option1: e.target.value })}
                            />
                        </p>
                        <p>
                            <label>Option2</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter the Option2'
                                value={initialValues.Option2}
                                onChange={e => setInitialValues({ ...initialValues, Option2: e.target.value })}
                            />
                        </p>
                        <p>
                            <label>Option3</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter the Option3'
                                value={initialValues.Option3}
                                onChange={e => setInitialValues({ ...initialValues, Option3: e.target.value })}
                            />
                        </p>
                        <p>
                            <label>Option4</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter the Option4'
                                value={initialValues.Option4}
                                onChange={e => setInitialValues({ ...initialValues, Option4: e.target.value })}
                            />
                        </p>
                    </div>
                    <div className="flex justify-content-center align-items-center mb-4 gap-2">
                        <label htmlFor="input-metakey">Status</label>
                        <InputSwitch inputId="input-metakey" checked={questStatus} onChange={(e) => setquestStatus(e.value)} />
                    </div>
                </div>
            </div>
            <div className='specialBtnCls button-container'>
                <button type="button" className="button-cancle-cls" style={{ marginTop: '-11px', marginRight: '12px' }}
                    onClick={handleClose}
                >Cancel</button>
                <button type="button" className="button-savecls" style={{ marginTop: '-11px' }}
                    onClick={() => CreateSymPtomData()}
                >Save</button>
            </div>
            <ToastContainer />
        </div>
    )
}