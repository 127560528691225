import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import RecentOrders from "../RecentOrders/RecentOrders.tsx";
import { barChartDropdown } from "../data/data.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  const { isActive } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    setChartData({
      labels: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
      datasets: [
        {
          label: "Sales $",
          data: [18127, 22201, 19490, 17938, 24182, 17842, 22475],
          borderColor: "#0B7C00",
          backgroundColor: "#0B7C69",
        },
      ],
    });
    setChartOptions({
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Daily Revenue",
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    });
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          className={
            isActive ? "barchartAlignCls_darkMode" : "barchartAlignCls"
          }
        >
          <div className="col-sm-12 col-md-6 barchartDropdownCls">
            {/* <label
							htmlFor="dropdown"
							className="PatientNameCls"
						>
							Doctor Name :
						</label>
						&nbsp;&nbsp; */}
            <select
              className="dateListCls"
              value={value}
              onChange={handleChange}
            >
              <option value="">Select</option>
              {barChartDropdown.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.date}
                </option>
              ))}
            </select>
          </div>
          {/* <Bar data={chartData} options={chartOptions} /> */}
          <div className="analytics-bar-chart">
            Analytics will be shwon here...
          </div>
        </div>
        <div
          className={isActive ? "recentOrderCls_darkMode" : "recentOrderCls"}
        >
          <RecentOrders />
        </div>
      </div>
    </>
  );
};

export default BarChart;
