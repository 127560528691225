import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import Sidebar from "../../AdminPanel/SideBar/Sidebar.tsx";
import Navbar from "../../AdminPanel/Navbar/Navbar.tsx";
import CreateSpecialization from "./CreateSpecialization.js";
import UpdateSpecialization from "./UpdateSpecialization.js";
import "../TeleHealth.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import NoImage from "../../TeleHealth/Images/NoImage.png";
import { API_URL } from "../../../config";

function SpecializationsList() {
  const UAToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [showAddMode, setShowAddMode] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [getReloadData, setGetReloadData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const apiEndPoint = API_URL;

  const getSeverity = (status) => {
    switch (status) {
      case "Active":
        return "active-status";
      case "In-Active":
        return "In-active-status";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (UAToken && UAToken !== "" && UAToken !== null) {
      navigate("/specializationsList");
    } else {
      navigate("/");
    }
  }, [UAToken, navigate]);

  useEffect(() => {
    getSpecializations();
  }, [searchTerm]);

  useEffect(() => {
    if (getReloadData) {
      getSpecializations();
      setGetReloadData(false);
    }
  }, [getReloadData]);

  const handleChange = () => {
    setIsActive(!isActive);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const getSpecializations = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${apiEndPoint}/specializations/get-specializations-list?search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const actualData = await response.json();
      setData(actualData.data);
    } catch (error) {
      console.error("Error fetching specializations:", error);
      toast.error("Failed to load specializations");
      if (error.message.includes("401")) {
        navigate("/login");
      }
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          title="Edit"
          rounded
          outlined
          className="mr-2 editBtnFocus"
          onClick={() => {
            setRowItem(rowData);
            setShowEditMode(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          title="Delete"
          rounded
          outlined
          className="deleteBtnFocus"
          onClick={() => deleteConfirm(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (data) => {
    return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
  };

  const imageBodyTemplate = (data) => {
    return (
      <img
        height={60}
        width={60}
        src={data.image || NoImage}
        alt={data.specialization}
        style={{ objectFit: "cover", borderRadius: "4px" }}
      />
    );
  };

  const deleteConfirm = (specializationId) => {
    confirmDialog({
      message: "Are you sure you want to delete this Specialization?",
      header: "Confirmation",
      accept: () => deleteSpecialization(specializationId),
    });
  };

  const deleteSpecialization = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${apiEndPoint}/specializations/delete-specialization/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete specialization");
      }

      const data = await response.json();
      if (response.status === 200) {
        toast.success("Specialization deleted successfully");
        getSpecializations();
      } else {
        toast.error(data.message || "Failed to delete specialization");
      }
    } catch (error) {
      console.error("Error deleting specialization:", error);
      toast.error("Failed to delete specialization");
    }
  };

  return (
    <div className="dashboard">
      <Sidebar isActive={isActive} />
      <div className="dashboardContainer">
        <Navbar isActive={isActive} handleChange={handleChange} />
        <div>
          <div className="users-page">
            <div className="container">
              <div className="users-list">
                <div className="list-header">
                  <div className="header-users">
                    <h6>Specializations</h6>
                  </div>
                  <div className="header-actions">
                    <span className="p-input-icon-left search-input">
                      <i className="pi pi-search" />
                      <InputText
                        placeholder="Search specializations"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </span>
                    <button
                      className="addUserCls"
                      title="Add Specialization"
                      onClick={() => setShowAddMode(true)}
                    >
                      <i
                        className="pi pi-plus"
                        style={{ paddingRight: "6px" }}
                      ></i>{" "}
                      Create
                    </button>
                  </div>
                </div>
                <DataTable value={data} paginator rows={10}>
                  <Column
                    field="specialization"
                    header="Specialization"
                    sortable
                    filter
                    style={{ width: "40%" }}
                  />
                  <Column
                    field="image"
                    header="Image"
                    body={imageBodyTemplate}
                    style={{ width: "20%" }}
                  />
                  <Column
                    field="status"
                    header="Status"
                    body={statusBodyTemplate}
                    sortable
                    filter
                    style={{ width: "20%" }}
                  />
                  <Column
                    header="Actions"
                    body={actionBodyTemplate}
                    style={{ width: "20%" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>

          <Dialog
            header="Add Specialization"
            visible={showAddMode}
            style={{ width: "40vw" }}
            onHide={() => setShowAddMode(false)}
          >
            <CreateSpecialization
              setGetReloadData={setGetReloadData}
              setUserAdded={() => setShowAddMode(false)}
            />
          </Dialog>

          <Dialog
            header="Edit Specialization"
            visible={showEditMode}
            style={{ width: "40vw" }}
            onHide={() => setShowEditMode(false)}
          >
            <UpdateSpecialization
              visible={showEditMode}
              setGetReloadData={setGetReloadData}
              updateData={rowItem}
              setUserEdited={() => setShowEditMode(false)}
            />
          </Dialog>

          <ConfirmDialog />
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default SpecializationsList;
