import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TelehealthDoctors.css";
import { API_URL } from "../../../config";

const CreateTelehealthDoctor = ({ setGetReloadData, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    medicalRegistrationNumber: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    medicalRegistrationNumber: "",
    esignature: "",
  });
  const [esignatureFile, setEsignatureFile] = useState(null);
  const [esignaturePreview, setEsignaturePreview] = useState(null);
  const apiEndPoint = API_URL;

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      phoneNumber: "",
      email: "",
      medicalRegistrationNumber: "",
      esignature: "",
    };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    } else if (formData.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters";
      isValid = false;
    }

    if (!formData.medicalRegistrationNumber.trim()) {
      newErrors.medicalRegistrationNumber =
        "Medical Registration Number is required";
      isValid = false;
    }

    if (!esignatureFile) {
      newErrors.esignature = "E-Signature is required";
      isValid = false;
    }

    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Enter a valid 10-digit phone number";
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Enter a valid email address";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEsignatureFile(file);
      setErrors((prev) => ({
        ...prev,
        esignature: "",
      }));

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setEsignaturePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeUploadedFile = () => {
    setEsignatureFile(null);
    setEsignaturePreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");

      // Create FormData for file upload
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("email", formData.email);
      formDataToSend.append(
        "medicalRegistrationNumber",
        formData.medicalRegistrationNumber
      );
      if (esignatureFile) {
        formDataToSend.append("esignature", esignatureFile);
      }

      const response = await fetch(
        `${apiEndPoint}/teledoctors/create-telehealth-doctor`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            // Don't set Content-Type when sending FormData, browser will set it with boundary
          },
          body: formDataToSend,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create doctor");
      }

      const data = await response.json();
      toast.success("Doctor created successfully");
      setGetReloadData(true);
      onClose();
    } catch (err) {
      toast.error(err.message || "Failed to create doctor");
    }
  };

  return (
    <div className="user-view _add-view">
      <div className="box">
        <div className="row">
          <div>
            <p>
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </p>
          </div>

          <div>
            <p>
              <label>Phone Number</label>
              <input
                type="tel"
                className="form-control"
                placeholder="Enter Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              {errors.phoneNumber && (
                <div className="error">{errors.phoneNumber}</div>
              )}
            </p>
          </div>

          <div>
            <p>
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </p>
          </div>

          <div>
            <p>
              <label>Medical Registration Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Medical Registration Number"
                name="medicalRegistrationNumber"
                value={formData.medicalRegistrationNumber}
                onChange={handleChange}
              />
              {errors.medicalRegistrationNumber && (
                <div className="error">{errors.medicalRegistrationNumber}</div>
              )}
            </p>
          </div>

          <div>
            <p>
              <label>E-Signature</label>
              <input
                type="file"
                className="form-control"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
              />
              {errors.esignature && (
                <div className="error">{errors.esignature}</div>
              )}
            </p>
          </div>

          {esignaturePreview && (
            <div>
              <p>
                <label>E-Signature Preview</label>
                <div className="file-preview" style={{ position: "relative" }}>
                  <img
                    src={esignaturePreview}
                    alt="E-signature"
                    style={{ maxWidth: "200px", maxHeight: "100px" }}
                  />
                  <button
                    type="button"
                    className="remove-file-btn"
                    onClick={removeUploadedFile}
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      background: "rgba(255,255,255,0.7)",
                      border: "none",
                      borderRadius: "50%",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="pi pi-trash" style={{ color: "red" }}></i>
                  </button>
                </div>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="specialBtnCls">
        <button type="button" className="button-cancle-cls" onClick={onClose}>
          Cancel
        </button>
        <button type="button" className="button-savecls" onClick={handleSubmit}>
          Save
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateTelehealthDoctor;
